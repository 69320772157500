import { timelineSteps } from '../constants/timelineStep';
import { RegularCharacter } from '../constants/regular';
import { SceneObject } from '../constants/object';
import { P5CustomObject } from '../constants/p5object';

export const insertNewStep = (timeline, time, action) => {
  if (!timeline) {
    return {
      ...timelineSteps,
      time, // update the time to the provided value
      actions: [
        ...timelineSteps.actions, // spread the current actions
        action, // add the new action
      ],
    };
  }
};

export function sumTimelineDurations(timeline) {
  return timeline.reduce((totalDuration, step) => {
    // Find the longest duration within the current step
    const maxStepDuration = step.actions.reduce((maxDuration, action) => {
      return Math.max(maxDuration, action.parameters?.duration || 0);
    }, 0);

    return totalDuration + maxStepDuration;
  }, 0);
}

export const getLongestDuration = (actions) => {
  if (!actions || !Array.isArray(actions)) return 0;
  let maxDuration = 0;

  actions.forEach((action) => {
    if (
      action?.parameters?.duration &&
      typeof action.parameters.duration === 'number'
    ) {
      maxDuration = Math.max(maxDuration, action.parameters.duration);
    }
  });

  return maxDuration;
};

export const getCurrentStepIndex = (timeline, currentTime) => {
  if (!timeline || timeline.length === 0) return 0;

  let index = -1;
  for (let i = 0; i < timeline.length; i++) {
    if (timeline[i].time <= currentTime) {
      index = i;
    } else {
      break;
    }
  }
  return index;
};

export function getCurrentStep(timeline, currentTime) {
  let currentStep = null;

  for (let i = 0; i < timeline.length; i++) {
    if (timeline[i].time <= currentTime) {
      currentStep = timeline[i];
    } else {
      break; // Since the timeline is sorted by time, we can stop early
    }
  }

  return currentStep;
}

// export function getLastStep(timeline) {
//     if (!timeline || timeline.length === 0) {
//         return null; // or you can return undefined or throw an error, depending on your needs
//     }
//     return timeline[timeline.length - 1];
// }

export function getLastStep(timeline) {
  return timeline && timeline.length > 0 ? timeline[timeline.length - 1] : null;
}

export function isLastStep(timeline, currentTime) {
  const currentStep = getCurrentStep(timeline, currentTime)
  const lastStep = getLastStep(timeline)
  return lastStep == currentStep
}

export function getXStep(timeline, x) {
  // Validate that the timeline exists and is an array
  if (!timeline || !Array.isArray(timeline)) {
    return null;
  }

  // Validate that x is a number and within bounds
  if (typeof x !== 'number' || x < 0 || x >= timeline.length) {
    return null; // or handle the error as needed
  }

  // Return the timeline step at the given index
  return timeline[x];
}

export function extractUniqueTargets(actions) {
  const targets = new Set();

  actions.forEach((action) => {
    if (action.target && action.type === 'move') {
      targets.add(action.target);
    }
  });

  return Array.from(targets);
}

export function normalizeTimelineTimes(timeline) {
  let currentTime = 0;

  return timeline.map((entry) => {
    // Find the longest duration in the actions, safely handling undefined parameters
    let maxDuration = entry.actions.reduce((max, action) => {
      const duration = action?.parameters?.duration || 0;
      return Math.max(max, duration);
    }, 0);

    // Update the time key
    let updatedEntry = { ...entry, time: currentTime };

    // Move to the next time step
    currentTime += maxDuration;

    return updatedEntry;
  });
}

export function saveToTimeline(
  timeline,
  currentTime,
  draggedEntity,
  newX,
  newY,
  newRotation,
  char,
  stepW,
  stepH
) {
  const index = getCurrentStepIndex(timeline, currentTime);

  // If no valid index is found, return the original timeline
  if (index === -1) return timeline;

  // Clone the timeline step at the found index
  let step = { ...timeline[index] };

  // Initialize actions array if it doesn't exist
  if (!step.actions) {
    step.actions = [];
  }

  // Check if the action exists in the step
  let actionFound = false;
  let updatedActions = step.actions.map((action) => {
    if (action.target === draggedEntity.id && action.type === 'move') {
      actionFound = true;

      return {
        ...action,
        parameters: {
          ...action.parameters,
          position: {
            x: newX,
            y: newY,
          },
          rotation: newRotation,
          // Only include w and h if draggedEntity is "scale"
          ...(draggedEntity.type === 'scale' ? { w: stepW, h: stepH } : {}),
        },
      };
    }

    return action;
  });

  // If no existing action was found, insert a new one
  if (!actionFound) {
    const newAction = {
      target: draggedEntity.id,
      type: 'move',
      parameters: {
        position: {
          x: newX,
          y: newY,
        },
        rotation: newRotation,
        duration: 1,
        easing: 'linear',
        // Only include w and h if draggedEntity is "scale"
        ...(draggedEntity.type === 'scale' ? { w: stepW, h: stepH } : {}),
      },
    };
    updatedActions.push(newAction);
  }

  // Update the step's actions
  step.actions = updatedActions;

  // Clone the timeline array and update only the specific step
  let updatedTimeline = [...timeline];
  updatedTimeline[index] = step;

  return updatedTimeline;
}

export function classInstanceToObject(instance) {
  const obj = {};
  // Get all own property names (including non-enumerable ones)
  Object.getOwnPropertyNames(instance).forEach((key) => {
    if (key === 'bodyImg' || key === 'eyeLidImageP5') {
      obj[key] = instance[key].image;
    } else {
      obj[key] = instance[key];
    }
  });
  return obj;
}

export const importTimelineUtils = (json) => {
  const output = {
    timeline: [],
    background: '',
    characters: [],
    objects: [],
    dialogs: []
  };
  if (json.dialogs && Array.isArray(json.dialogs)) {
    output.dialogs = json.dialogs;
  }
  if (json.timeline && Array.isArray(json.timeline)) {
    output.timeline = json.timeline;
  }
  if (json.scene) {
    output.background = json.scene.background;
  }
  if (json.characters && Array.isArray(json.characters)) {
    const restoredCharacters = json.characters.map((char) => {
      const restored = { ...char };
      Object.setPrototypeOf(restored, RegularCharacter.prototype);
      return restored;
    });
    output.characters = restoredCharacters;
  }
  if (json.objects && Array.isArray(json.objects)) {
    const restoredObjects = json.objects.map((obj) => {
      const restored = { ...obj };
      // Check if it's a custom p5 object and restore the proper prototype
      if (restored.objectType === 'custom' || restored.type === 'p5custom') {
        Object.setPrototypeOf(restored, P5CustomObject.prototype);
        // Clear out the particles array so that new particles are generated.
        restored.particles = [];
        // Also force reinitialization if needed:
        restored.isInitialized = false;
      } else {
        Object.setPrototypeOf(restored, SceneObject.prototype);
      }

      return restored;
    });
    output.objects = restoredObjects;
  }

  return output;
};

export const insertEmptyStepUtils=(timeline)=>{
  const newAction = {
    target: '',
    type: 'move',
    parameters: {
      position: { x: 50, y: 50 },
      duration: 1,
      easing: 'linear',
    },
  };

  const newStep = {
    time: timeline.length, // Assign next available index as time
    actions: [newAction],
  };
  
  const newTimeline = normalizeTimelineTimes([...timeline, newStep]);
  return newTimeline;
}


export const createCachedTimelineUtils = async (timeline, dialogs) => {
    // Map over each step asynchronously to produce a new timeline array
    const newSteps = await Promise.all(
      timeline.map(async (step, stepIndex) => {
        console.log(`Step ${stepIndex} at time: ${step.time}`);
        
        // Map over each action asynchronously
        const newActions = await Promise.all(
          step.actions.map(async (action, actionIndex) => {
            if (action.type === "audio") {
              // Clone the audio action and its parameters
              const newAction = { ...action, parameters: { ...action.parameters } };
              
              // If uuid is missing, assign a random one
              if (!newAction.parameters.uuid) {
                newAction.parameters.uuid =
                  typeof crypto !== "undefined" && crypto.randomUUID
                    ? crypto.randomUUID()
                    : Math.random().toString(36).substring(2, 15);
                console.log(
                  `Audio action at step ${stepIndex}, action ${actionIndex} was missing uuid. Assigned uuid: ${newAction.parameters.uuid}`
                );
              }
              
              // If duration is missing, calculate it using the "seek trick"
              if (!newAction.parameters.duration) {
                console.log(
                  `Audio action at step ${stepIndex}, action ${actionIndex} is missing duration. Calculating duration...`
                );
                // Assume the asset URL is provided in parameters
                const audioId = newAction.parameters.id;
                if (audioId) {
                  // find the audio in dialogs
                  const audioAsset = dialogs.find(audio => audio.id == audioId);
                  try {
                    newAction.parameters.duration = await new Promise((resolve, reject) => {
                      const tempAudio = new Audio(audioAsset.asset);
                      tempAudio.preload = "metadata";
                      tempAudio.addEventListener("loadedmetadata", () => {
                        if (tempAudio.duration === Infinity) {
                          // Use the seek trick to get the actual duration
                          tempAudio.currentTime = Number.MAX_SAFE_INTEGER;
                          tempAudio.ontimeupdate = () => {
                            tempAudio.ontimeupdate = null;
                            tempAudio.currentTime = 0; // reset after updating duration
                            resolve(tempAudio.duration);
                          };
                        } else {
                          resolve(tempAudio.duration);
                        }
                      });
                      tempAudio.addEventListener("error", (error) => {
                        reject(error);
                      });
                    });
                  } catch (error) {
                    console.error("Failed to get audio duration:", error);
                    newAction.parameters.duration = 1; // fallback duration
                  }
                } else {
                  console.warn(
                    `No asset URL provided for audio action at step ${stepIndex}, action ${actionIndex}`
                  );
                  newAction.parameters.duration = 1; // fallback duration
                }
                console.log(
                  `Calculated duration for step ${stepIndex}, action ${actionIndex}: ${newAction.parameters.duration}`
                );
              }
              
              return newAction;
            }
            // For non-audio actions, simply return the original action
            return action;
          })
        );
        
        // Return a new step with the updated actions array
        return { ...step, actions: newActions };
      })
    );
    return newSteps
};
  