import '../styles/controls.css';
import React, { useContext } from 'react';
import { GlobalContext } from '../App';
import BackgroundControl from './controls/Background';
import CharacterControl from './controls/RigCharacter';
import ActionControl from './controls/Action';
import TimelineControl from './controls/Timeline';
import Audioline from './Audioline';
import RegularCharacterControl from './controls/RegularCharacter';
import StepControl from './controls/Step';
import ObjectControl from './controls/ObjectControl';
import P5ObjectControl from './controls/P5ObjectControl';


const Controls = () => {
  const { } =
    useContext(GlobalContext);


  return (
    <>
      <h3>Controls</h3>
      <div className="controls">
        {/* Upload Background */}
        {/* <BackgroundControl /> */}

        {/* <EditControl /> */}

        {/* Insert new step */}
        {/* <TimelineControl /> */}

        {/* Edit Current Step */}
        {/* <StepControl /> */}

        {/* Insert audio at current step */}
        {/* <Audioline /> */}

        {/* 
        <div className="outline flex-container row">
          <RegularCharacterControl />

          <ObjectControl />
          <P5ObjectControl />
        </div> */}

        <ActionControl />

      </div>
    </>
  );
};

export default Controls;
