import '../styles/timeline.css';
import { GlobalContext } from '../App';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { getLongestDuration, getCurrentStep } from '../utils/timeline';
import CurrentStep from './CurrentStep';

const Timeline = () => {
  const { timeline, currentTime, setCurrentTime, characters, objects } =
    useContext(GlobalContext);
  const timelineRef = useRef(null);
  const [playheadPosition, setPlayheadPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  let prevLeft = 0;

  useEffect(() => {
    setPlayheadPosition(currentTime * 100);
  }, [currentTime]);

  const handleEventClick = (time) => {
    setCurrentTime(time);
    setPlayheadPosition(time * 100);

    // Get the step at this time
    const currentStep = getCurrentStep(timeline, time);

    // Update character and object positions based on the step
    if (currentStep && currentStep.actions) {
      currentStep.actions.forEach((action) => {
        if (action.type === 'move') {
          // Find the target (either character or object)
          let target = characters.find((c) => c.id === action.target);
          if (!target) {
            target = objects.find((o) => o.id === action.target);
          }

          if (target) {
            // Update position and other properties
            target.offsetX = action.parameters.position.x;
            target.offsetY = action.parameters.position.y;
            if (action.parameters.rotation !== undefined) {
              target.bX.value = (action.parameters.rotation * Math.PI) / 180;
            }
            if (action.parameters.w !== undefined) {
              target.bW.value = action.parameters.w;
            }
            if (action.parameters.h !== undefined) {
              target.bH.value = action.parameters.h;
            }
          }
        }
      });
    }
  };

  const handleMouseDown = (event) => {
    if (event.target.classList.contains('timeline-playhead')) {
      setIsDragging(true);
      event.preventDefault();
      return;
    }
  };

  const getRightmostCoordinate = (timeline) => {
    if (!timeline || !Array.isArray(timeline)) return 0;

    let rightmost = 0;
    let prevLeft = 0;

    timeline.forEach((step, index) => {
      const longestDuration = getLongestDuration(step?.actions);
      const previousDuration =
        index === 0 ? 0 : getLongestDuration(timeline[index - 1]?.actions);

      prevLeft = index === 0 ? 0 : prevLeft + previousDuration * 100;
      const rightEdge = prevLeft + longestDuration * 95;

      rightmost = Math.max(rightmost, rightEdge);
    });

    return rightmost;
  };

  const handleMouseMove = (event) => {
    if (!isDragging || !timelineRef.current) return;
    const timelineRect = timelineRef.current.getBoundingClientRect();
    let newLeft = event.clientX - timelineRect.left;

    // Ensure the playhead stays within bounds
    newLeft = Math.max(0, Math.min(newLeft, timelineRect.width));
    if (newLeft >= getRightmostCoordinate(timeline)) {
      return;
    }

    setPlayheadPosition(newLeft);
    setCurrentTime(newLeft / 100);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <>
      <div
        className="timeline"
        id="timeline"
        ref={timelineRef}
        onMouseMove={isDragging ? handleMouseMove : null}
        onMouseUp={handleMouseUp}
      >
        {timeline.map((step, index) => {
          const longestDuration = getLongestDuration(step?.actions);
          const previousDuration =
            index === 0 ? 0 : getLongestDuration(timeline[index - 1].actions);
          prevLeft = index === 0 ? 0 : prevLeft + previousDuration * 100;
          return (
            <div
              className="timeline-event move"
              style={{
                left: `${prevLeft}px`,
                width: `${longestDuration * 95}px`,
              }}
              key={`${index}_step`}
              onClick={() => handleEventClick(step.time)}
              role="button"
              tabIndex={0}
            >
              {step?.time} :
              {step?.actions?.map((action, index2) => (
                <span key={`${index}_${index2}`}>
                  {action?.target} : {action?.type}
                </span>
              ))}
            </div>
          );
        })}

        <div
          className="timeline-playhead"
          style={{ left: `${playheadPosition}px` }}
          onMouseDown={handleMouseDown}
        ></div>
      </div>
      Q button down and drag time line to see changes
      <div className="flex-col">
        <textarea
          id="timeline-json"
          placeholder="Timeline JSON will appear here"
          value={JSON.stringify(timeline, null, 2)}
          readOnly
        />
        <CurrentStep />
      </div>
    </>
  );
};

export default Timeline;
