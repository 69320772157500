import '../../styles/controls.css';
import React, { useContext } from 'react';
import { GlobalContext } from '../../App';
import BackgroundControl from './Background';
import ActionControl from './Action';
import TimelineControl from './Timeline';
import Audioline from '../Audioline';
import RegularCharacterControl from './RegularCharacter';
import ObjectControl from './ObjectControl';
import P5ObjectControl from './P5ObjectControl';
import useModal from '../../hooks/useModal';


const Topbar = () => {
  const {  } =
    useContext(GlobalContext);

  const {
    isOpen: isAddCharacterModalOpen,
    openModal: openAddCharacterModal,
    closeModal: closeAddCharacterModal,
    Modal: AddCharacterModal,
  } = useModal();
  const {
    isOpen: isStepModalOpen,
    openModal: openStepModal,
    closeModal: closeStepModal,
    Modal: StepModal,
  } = useModal();
  const {
    isOpen: isP5ModalOpen,
    openModal: openP5Modal,
    closeModal: closeP5Modal,
    Modal: P5Modal,
  } = useModal();
  const {
    isOpen: isAudioModalOpen,
    openModal: openAudioModal,
    closeModal: closeAudioModal,
    Modal: AudioModal,
  } = useModal();
  const {
    isOpen: isObjectModalOpen,
    openModal: openObjectModal,
    closeModal: closeObjectModal,
    Modal: ObjectModal,
  } = useModal();
  const {
    isOpen: isBackgroundModalOpen,
    openModal: openBackgroundModal,
    closeModal: closeBackgroundModal,
    Modal: BackgroundModal,
  } = useModal();

  return (
    <>
      <h3>Controls</h3>
      <div className="controls">

        <AddCharacterModal>
          <RegularCharacterControl />
        </AddCharacterModal>
        <StepModal>
          <TimelineControl />
        </StepModal>
        <P5Modal>
          <P5ObjectControl />
        </P5Modal>
        <AudioModal>
          <Audioline />
        </AudioModal>
        <ObjectModal>
          <ObjectControl />
        </ObjectModal>
        <BackgroundModal>
          <BackgroundControl />
        </BackgroundModal>
        <button onClick={openBackgroundModal}>Background</button>
        <button onClick={openAddCharacterModal}>Characters</button>
        <button onClick={openStepModal}>Steps</button>
        <button onClick={openAudioModal}>Audio</button>
        <button onClick={openObjectModal}>Objects</button>
        <button onClick={openP5Modal}>P5</button>
        
      </div>
    </>
  );
};

export default Topbar;
