// CharacterModal.js
import '../styles/controls.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from '../App';
import Slider from '../components/controls/Slider';
import { RegularCharacter } from '../constants/regular';

const CharacterModal = ({ isOpen, closeModal, modalData }) => {
  const { characters, setCharacters } = useContext(GlobalContext);
  const [selectedChar, setSelectedChar] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dragRef = useRef(false);
  const initialPositionRef = useRef({ x: 0, y: 0 });
  const dragStartRef = useRef({ x: 0, y: 0 });
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalData) {
      setSelectedChar(characters.find((char) => char.id === modalData));
    }
  }, [modalData, characters]);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      // Center the modal on open
      const rect = modalRef.current.getBoundingClientRect();
      setPosition({
        x: (window.innerWidth - rect.width) / 2,
        y: (window.innerHeight - rect.height) / 3,
      });
    }
  }, [isOpen]);

  const handleMouseDown = (e) => {
    if (e.target.className === 'btn' || e.target.type === 'range') return;

    dragRef.current = true;
    initialPositionRef.current = position;
    dragStartRef.current = { x: e.clientX, y: e.clientY };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!dragRef.current) return;

    const dx = e.clientX - dragStartRef.current.x;
    const dy = e.clientY - dragStartRef.current.y;

    setPosition({
      x: initialPositionRef.current.x + dx,
      y: initialPositionRef.current.y + dy,
    });
  };

  const handleMouseUp = () => {
    dragRef.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const onSliderChange = (e, sliderName) => {
    const newValue = parseFloat(e.target.value);
    const updatedSliders = selectedChar.sliders.map((slider) =>
      slider.name === sliderName ? { ...slider, value: newValue } : slider
    );
    selectedChar[sliderName].value = newValue;
    setSelectedChar({ ...selectedChar, sliders: updatedSliders });
  };

  const toggleMouth = () => {
    setCharacters((prevCharacters) =>
      prevCharacters.map((char) => {
        if (char.id === selectedChar.id) {
          const updatedChar = { ...char, talking: !char.talking };
          Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
          return updatedChar;
        }
        return char;
      })
    );

    setSelectedChar((prevChar) => {
      const updatedChar = { ...prevChar, talking: !prevChar.talking };
      Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
      return updatedChar;
    });
  };

  const toggleEyes = () => {
    setCharacters((prevCharacters) =>
      prevCharacters.map((char) => {
        if (char.id === selectedChar.id) {
          const updatedChar = { ...char, showEyes: !char.showEyes };
          Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
          return updatedChar;
        }
        return char;
      })
    );

    setSelectedChar((prevChar) => {
      const updatedChar = { ...prevChar, showEyes: !prevChar.showEyes };
      Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
      return updatedChar;
    });
  };

  if (!isOpen) return null;

  return (
    <div
      ref={modalRef}
      style={{
        position: 'fixed',
        top: 100,
        left: 500,
        transform: `translate(${position.x}px, ${position.y}px)`,
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        cursor: dragRef.current ? 'grabbing' : 'grab',
        userSelect: 'none',
        zIndex: 1000,
        minWidth: '350px',
        maxHeight: '80vh', // Limit height to percentage of viewport
        display: 'flex',
        flexDirection: 'column'
      }}
      onMouseDown={handleMouseDown}
    >
      <div className="modal-header" style={{ 
        marginBottom: '20px',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1001,
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <h2 style={{ margin: 0 }}>Character Controls</h2>
        <button
          onClick={closeModal}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            padding: '5px 10px',
          }}
        >
          ×
        </button>
      </div>

      <div className="sliders-control" style={{ 
        overflowY: 'auto',
        maxHeight: 'calc(80vh - 80px)' // Leave space for header
      }}>
        <div id="sliders">
          {selectedChar &&
            selectedChar.sliders.map((slider, index) => (
              <Slider
                key={`${index}_slider`}
                slider={slider}
                handleInput={onSliderChange}
              />
            ))}
        </div>
        {selectedChar != null && (
          <div>
            <button className="btn" onClick={toggleMouth}>
              Show/Hide Mouth
            </button>
            <button className="btn" onClick={toggleEyes}>
              Show/Hide Eyes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CharacterModal;
