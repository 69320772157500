import { v4 as uuidv4 } from 'uuid';
import { SceneObject } from '../constants/object';
import { getCurrentStepIndex, normalizeTimelineTimes } from './timeline';

export function createNewObject({
  file,
  objects,
  setObjects,
  timeline,
  setTimeline,
  currentTime,
  alertFn = window.alert,
  option
}) {
  if (!file) {
    alertFn('No image file provided for creating a new object.');
    return Promise.reject(new Error('No file provided'));
  }

  const fileNameWithoutExt = file.name.split('.').slice(0, -1).join('.');
  const existingObject = objects.find((obj) => obj.id === fileNameWithoutExt);
  if (existingObject) {
    alertFn(`An object with the ID '${fileNameWithoutExt}' already exists.`);
    return Promise.reject(new Error('Object already exists'));
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const imageBase64 = reader.result;
      const randomUuid = uuidv4();
      const name = randomUuid;
      const finalId = fileNameWithoutExt;

      const maxZIndex = objects.reduce((max, obj) => {
        return (obj.zIndex || 0) > max ? obj.zIndex : max;
      }, 0);

      const newObject = new SceneObject(50, 50, finalId, name, option === "base64" ? imageBase64 : file.name,);
      newObject.zIndex = maxZIndex + 1;

      setObjects([...objects, newObject]);

      const currentIndex = getCurrentStepIndex(timeline, currentTime);
      let updatedTimeline = [...timeline];
      const currentStep =
        updatedTimeline.length > 0 ? updatedTimeline[currentIndex] : null;

      const newAction = {
        target: finalId,
        type: 'move',
        parameters: {
          position: { x: 50, y: 50 },
          duration: 1,
          easing: 'linear',
        },
      };

      if (currentStep) {
        const existingAction = currentStep.actions.find(
          (action) => action.target === finalId
        );
        if (!existingAction) {
          updatedTimeline[currentIndex] = {
            ...currentStep,
            actions: [...currentStep.actions, newAction],
          };
        }
      } else {
        const newStep = {
          time: currentTime,
          actions: [newAction],
        };
        updatedTimeline.push(newStep);
        updatedTimeline.sort((a, b) => a.time - b.time);
        updatedTimeline = normalizeTimelineTimes(updatedTimeline);
      }

      setTimeline(updatedTimeline);
      resolve(newObject);
    };

    reader.readAsDataURL(file);
  });
}
