import './styles/App.css';
import React, { createContext, useState, useEffect, useRef } from 'react';
import P5Sketch from './components/P5Sketch';
import Timeline from './components/Timeline';
import Controls from './components/Controls';
import { ToastContainer, toast } from 'react-toastify';
import { importTimelineUtils } from './utils/timeline';
import Topbar from './components/controls/TopBar';
import UndoRedoBar from './components/controls/UndoRedoBar';
import { insertEmptyStepUtils } from './utils/timeline';

export const GlobalContext = createContext();

// Create a Set to track played audio
export const playedAudio = new Set();

// Create a Provider Component
export const GlobalProvider = ({ children }) => {
  const [scene, setScene] = useState({});
  const [characters, setCharacters] = useState([]);
  const [dialogs, setDialogs] = useState([]);
  const [objects, setObjects] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [cachedTimeline, setCachedTimeline] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRecording, setIsRecording] = useState(0);
  const [draggedEntity, setDraggedEntity] = useState(null);
  const [isCachedTimelineUpdated, setIsCachedTimelineUpdated] = useState(false);
  const totalTime = useRef(0);
  const [playedAudioSet, setPlayedAudioSet] = useState(new Set());

  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  function findSliderAttributes(attribute, char) {
    const toFind = char.sliders.find((value) => value.name === attribute);
    if (toFind) {
      char[attribute] = attribute;
    }
    return char;
  }

  useEffect(() => {
    if (id) {
      //fetch(`https://replicantlife.com/screenplay-assets?id=${id}`)
      const basehost = process.env.REACT_APP_BASE_HOST || "https://replicantlife.com"
      fetch(`${basehost}/screenplay-assets?id=${id}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('data', data);
          const dialogs = data.dialogs;
          setDialogs(dialogs)

          // let urlLoadedChars = data?.characters || [];
          // const urlLoadedTimeline = data?.timeline || [];
          // const urlLoadedBackground = data?.scene || {};
          // let urlLoadedObjects = data?.objects || [];

          // if (Array.isArray(urlLoadedChars)) {
          //   // load characters
          //   // const restoredCharacters = urlLoadedChars.map((char) => {
          //   //   let restored = { ...char };
          //   //   Object.setPrototypeOf(restored, RegularCharacter.prototype);
          //   //   // console.log('restored', restored);
          //   //   restored = findSliderAttributes('bW', restored);
          //   //   restored = findSliderAttributes('bH', restored);
          //   //   restored = findSliderAttributes('bX', restored);
          //   //   restored = findSliderAttributes('bY', restored);
          //   //   restored = findSliderAttributes('mSize', restored);
          //   //   restored = findSliderAttributes('mOffsetX', restored);
          //   //   restored = findSliderAttributes('mOffsetY', restored);
          //   //   return restored;
          //   // });
          //   // //console.log('restoredCharacters', restoredCharacters);
          //   // urlLoadedChars = restoredCharacters;
          //   // console.log('✅ Characters imported successfully:');
          //     const restoredCharacters = urlLoadedChars.map((char) => {
          //       const restored = { ...char };
          //       Object.setPrototypeOf(restored, RegularCharacter.prototype);
          //       return restored;
          //     });
          //     urlLoadedChars = restoredCharacters
          // }
          // if (Array.isArray(urlLoadedObjects)) {
          //   const restoredObjects = urlLoadedObjects.map((obj) => {
          //     const restored = { ...obj };
          //     // Check if it's a custom p5 object and restore the proper prototype
          //     if (restored.objectType === 'custom' || restored.type === 'p5custom') {
          //       Object.setPrototypeOf(restored, P5CustomObject.prototype);
          //       // Clear out the particles array so that new particles are generated.
          //       restored.particles = [];
          //       // Also force reinitialization if needed:
          //       restored.isInitialized = false;
          //     } else {
          //       Object.setPrototypeOf(restored, SceneObject.prototype);
          //     }
        
          //     return restored;
          //   });
          //   urlLoadedObjects = restoredObjects;
          // }
          if (data) {
            const output = importTimelineUtils(data);
            console.log("output", output)
            setBackgroundImage(output.background);
            setTimeline(output.timeline);
            setCharacters(output.characters);
            setObjects(output.objects);
          }
        });
    }
  }, [id]);

  // Add undo/redo state
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const isUndoingRef = useRef(false);

  const notify = (text) => toast(text);

  useEffect(()=>{
    if (timeline === "" || (Array.isArray(timeline) && timeline.length === 0)) {
      const newTimeline = insertEmptyStepUtils(timeline)
      setTimeline(newTimeline)
    }
  }, [timeline])

  // Handle undo action
  const handleUndo = () => {
    if (undoStack.length === 0) return;
    isUndoingRef.current = true;

    // Get the previous state
    const currentState = timeline;
    const previousState = undoStack[undoStack.length - 1];

    // Update undo stack
    setUndoStack((prev) => prev.slice(0, -1));

    // Add current state to redo stack
    setRedoStack((prev) => [...prev, currentState]);

    // Restore the previous state
    setTimeline(JSON.parse(JSON.stringify(previousState)));
  };

  // Handle redo action
  const handleRedo = () => {
    if (redoStack.length === 0) return;
    isUndoingRef.current = true;

    // Get the state to restore
    const currentState = timeline;
    const nextState = redoStack[redoStack.length - 1];

    // Update redo stack
    setRedoStack((prev) => prev.slice(0, -1));

    // Add current state to undo stack
    setUndoStack((prev) => [...prev, currentState]);

    // Restore the state
    setTimeline(JSON.parse(JSON.stringify(nextState)));
  };

  // Track timeline changes for undo/redo
  useEffect(() => {
    if (!isUndoingRef.current && timeline.length > 0) {
      // Add current state to undo stack
      setUndoStack((prev) => {
        const newStack = [...prev, timeline];
        // Limit stack size
        if (newStack.length > 20) {
          newStack.shift();
        }
        return newStack;
      });
      // Clear redo stack when new action is performed
      setRedoStack([]);
    } else {
      isUndoingRef.current = false;
    }
  }, [timeline]);

  // Add keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 'z') {
          e.preventDefault();
          handleUndo();
        } else if (e.key === 'y') {
          e.preventDefault();
          handleRedo();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (currentTime > totalTime.current + 1) {
      setIsPlaying(false);
    }
  }, [currentTime]);

  const consolelog = () => {
    console.log('characters', characters);
    console.log('timeline', timeline);
    console.log('dialogs', dialogs);
    console.log('objects', objects);
    console.log('scene', scene);
    console.log('backgroundImage', backgroundImage);
    console.log("cachedTimeline", cachedTimeline)
  };

  useEffect(()=>{
    setIsCachedTimelineUpdated(false)
  }, [timeline])

  return (
    <GlobalContext.Provider
      value={{
        scene, setScene,
        characters, setCharacters,
        objects, setObjects,
        timeline, setTimeline,
        dialogs, setDialogs,
        backgroundImage, setBackgroundImage,
        isPlaying, setIsPlaying,
        currentTime, setCurrentTime,
        totalTime,
        isRecording, setIsRecording,
        draggedEntity, setDraggedEntity,
        notify,
        playedAudioSet, setPlayedAudioSet,
        handleUndo,
        handleRedo,
        canUndo: undoStack.length > 0,
        canRedo: redoStack.length > 0,
        cachedTimeline, setCachedTimeline,
        isCachedTimelineUpdated,
        setIsCachedTimelineUpdated
      }}
    >
      <button onClick={consolelog}>console.log</button>
      {children}
      <ToastContainer />
    </GlobalContext.Provider>
  );
};

function App() {
  return (
    <GlobalProvider>
      <h1>Timeline Viewer</h1>
      <Topbar />
      <P5Sketch />
      <UndoRedoBar />
      <Timeline />
      <Controls />
    </GlobalProvider>
  );
}

export default App;
