import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../App';
import { P5CustomObject } from '../../constants/p5object';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentStepIndex } from '../../utils/timeline';

const P5ObjectControl = () => {
  const { objects, setObjects, timeline, setTimeline, currentTime } =
    useContext(GlobalContext);
  const [code, setCode] = useState('');

  const defaultCode = `
class Fire {
    constructor(baseX, baseY) {
        this.x = random(-20, 20);
        this.y = random(0, 20);
        this.vx = random(-1, 1);
        this.vy = random(-5, -1);
        this.alpha = 255;
        this.size = random(4, 12);
        this.color = {
            r: random(200, 255),
            g: random(50, 150),
            b: 0
        };
    }

    finished() {
        return this.alpha < 0;
    }

    update() {
        this.x += this.vx;
        this.y += this.vy;
        this.vy *= 0.98;
        this.alpha -= 5;
        this.size *= 0.97;
    }

    show(g) {
        g.noStroke();
        g.fill(this.color.r, this.color.g, this.color.b, this.alpha);
        g.ellipse(this.x, this.y, this.size);
    }
}
    `.trim();

  const handleCreateP5Object = () => {
    const name = prompt('Enter name for P5 object') || 'P5 Object';
    const id = `p5_${uuidv4()}`;

    // Find the maximum z-index
    const maxZIndex = objects.reduce((max, obj) => {
      return (obj.zIndex || 0) > max ? obj.zIndex : max;
    }, 0);

    const newObject = new P5CustomObject(
      100,
      100,
      id,
      name,
      code || defaultCode
    );
    newObject.zIndex = maxZIndex + 1;

    // Add the object to the objects array
    setObjects([...objects, newObject]);

    const currenStepIndex = getCurrentStepIndex(timeline, currentTime);

    // Add initial position to timeline
    const updatedTimeline = [...timeline];

    // If timeline is empty, create first keyframe at time 0
    if (updatedTimeline.length === 0) {
      updatedTimeline.push({
        time: 0,
        actions: [],
      });
    }

    // Add move action to the first keyframe
    const moveAction = {
      target: id,
      targetType: 'customObj',
      type: 'move',
      parameters: {
        position: {
          x: 100,
          y: 100,
        },
        rotation: 0,
        duration: 1,
        easing: 'linear',
      },
    };

    updatedTimeline[currenStepIndex].actions.push(moveAction);
    setTimeline(updatedTimeline);
  };

  return (
    <div className="flex-container column flex-one">
      <h4>Add P5.js Custom Object</h4>
      <textarea
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder={defaultCode}
        rows={10}
        style={{ width: '100%', marginBottom: '10px' }}
      />
      <button className="btn" onClick={handleCreateP5Object}>
        Create P5 Object
      </button>
    </div>
  );
};

export default P5ObjectControl;
