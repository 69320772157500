import '../styles/currentstep.css';
import React, { useContext, useMemo } from 'react';
import { GlobalContext } from '../App';
import { getCurrentStep } from '../utils/timeline';

const CurrentStep = React.memo(() => {
  const { timeline, currentTime } = useContext(GlobalContext);

  // Using useMemo to compute currentStep only when timeline or currentTime changes
  const currentStep = useMemo(
    () => getCurrentStep(timeline, currentTime),
    [timeline, currentTime]
  );

  return (
    <div className="currenstep">
      {currentStep && (
        <textarea
          value={JSON.stringify(currentStep, null, 1)}
          className="currenstep"
          style={{ height: '100%' }}
          readOnly
        />
      )}
    </div>
  );
});

export default CurrentStep;
