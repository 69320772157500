export function analyzeAudio(audioElement) {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const analyser = audioContext.createAnalyser();
  const source = audioContext.createMediaElementSource(audioElement);

  source.connect(analyser);
  analyser.connect(audioContext.destination);

  analyser.fftSize = 256;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  return {
    analyser,
    dataArray,
    getVolume: function () {
      analyser.getByteFrequencyData(this.dataArray);
      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        sum += this.dataArray[i];
      }
      return sum / bufferLength;
    },
  };
}

export function lipSync(audioElement, mouthImages, mouthContainer) {
  // Set up audio analysis
  const audio = analyzeAudio(audioElement);

  // Define volume thresholds for different mouth positions
  const thresholds = [
    { max: 10, mouth: 'closed' }, // Very quiet - mouth closed
    { max: 30, mouth: 'slightly-open' }, // Quiet - mouth slightly open
    { max: 60, mouth: 'half-open' }, // Medium - mouth half open
    { max: 80, mouth: 'Ee' },
    { max: 100, mouth: 'ah' },
    { max: 200, mouth: 'open' }, // Loud - mouth open
    { max: Infinity, mouth: 'wide-open' }, // Very loud - mouth wide open
  ];

  // Animation loop
  function animate() {
    const volume = audio.getVolume();

    // Select mouth based on volume
    let selectedMouth = 'closed'; // Default
    for (const threshold of thresholds) {
      if (volume <= threshold.max) {
        selectedMouth = threshold.mouth;
        break;
      }
    }

    // Update displayed mouth
    for (const mouth in mouthImages) {
      mouthImages[mouth].style.display =
        mouth === selectedMouth ? 'block' : 'none';
    }

    // Continue animation if audio is playing
    if (!audioElement.paused) {
      requestAnimationFrame(animate);
    } else {
      // Reset to closed mouth when not playing
      for (const mouth in mouthImages) {
        mouthImages[mouth].style.display =
          mouth === 'closed' ? 'block' : 'none';
      }
    }
  }

  // Start animation when audio plays
  audioElement.addEventListener('play', () => {
    animate();
  });
}

/**
 * Maps frequency data to a mouth shape.
 * This example uses the average amplitude of the frequency data to determine the shape.
 * Adjust the thresholds as needed for your specific audio.
 *
 * @param {Uint8Array | number[]} frequencyData - An array of frequency bin values.
 * @returns {string} One of "wide", "o", or "neutral".
 */
export function mapPhonemeToMouthShape(frequencyData) {
  let total = 0;
  const len = frequencyData.length;
  for (let i = 0; i < len; i++) {
    total += frequencyData[i];
  }
  const average = total / len;

  return average;

  // Define thresholds for mouth shape switching. Tune these based on your audio and needs.
  if (average > 150) {
    return 'wide'; // For high energy, for example when vowels like "ah" are pronounced.
  } else if (average > 80) {
    return 'o'; // For moderate energy, perhaps when forming rounded vowel sounds.
  } else {
    return 'neutral'; // For low energy, neutral or closed mouth.
  }
}
