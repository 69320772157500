import React, { useState, useRef } from 'react';

const Slider = ({ slider, handleInput }) => {
  
  const [editMode, setEditMode] = useState(false)
  const inputValueRef = useRef()

  const validate = () => {
    const numericValue = parseFloat(inputValueRef.current.value);
    if (isNaN(numericValue)) {
      alert("Please enter a valid number.");
      return;
    }
    
    // Ensure the value is within the slider's range
    if (numericValue < slider.min || numericValue > slider.max) {
      alert(`Value must be between ${slider.min} and ${slider.max}.`);
      return;
    }

    // Update the slider value
    handleInput({ target: { value: numericValue } }, slider.name);
    setEditMode(false);
  };

  return (
    <div className="slider-container">
      <label className="slider-label">{slider.label}</label>
      <input
        type="range"
        min={slider.min}
        max={slider.max}
        step={slider.step}
        value={slider.value}
        onChange={(e) => {
          handleInput(e, slider.name);
        }}
      />
      <span>
        Value: {!editMode && <>{slider.value}</>}
        <button onClick={()=>setEditMode(!editMode)}>Edit manually</button>
        {
          editMode && <>
            <input type="text" defaultValue={slider.value} ref={inputValueRef} />
            <button onClick={validate}>Save</button>
          </>
        }
      </span>

    </div>
  );
};

export default Slider;
