// Helper function to convert a blob URL to a Base64 string
export const convertBlobUrlToBase64 = async (blobUrl) => {
  if (!blobUrl) {
    return ''; // If there's no image, return an empty string
  }
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const cleanObjectForExport = (obj) => {
  // Create a copy without circular references
  const cleaned = { ...obj };
  // Remove p5.js image objects that can't be serialized
  delete cleaned.bodyImg;
  delete cleaned.eyeLidImageP5;
  delete cleaned.eyeBallImageP5;
  delete cleaned.leftImageP5;
  delete cleaned.rightImageP5;
  delete cleaned.backImageP5;
  return cleaned;
};

export const cleanBase64 = (obj) => {
  // Create a copy without circular references
  const cleaned = { ...obj };
  // Remove p5.js image objects that can't be serialized
  delete cleaned.bodyImg;
  delete cleaned.eyeLidImageP5;
  delete cleaned.eyeBallImageP5;
  delete cleaned.leftImageP5;
  delete cleaned.rightImageP5;
  delete cleaned.backImageP5;
  delete cleaned.image;
  delete cleaned.backImage;
  delete cleaned.rightImage;
  delete cleaned.leftImage;
  return cleaned;
};
