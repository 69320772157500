import '../../styles/controls.css';
import React, { useContext } from 'react';
import { GlobalContext } from '../../App';


const UndoRedoBar = () => {
  const { handleUndo, handleRedo, canUndo, canRedo } =
    useContext(GlobalContext);


  return (
    <>
        <div className="flex" style={{ gap:"4px" }}>
            {/* <div className="control-header"> */}
            <button
              className={`btn undo-btn ${!canUndo ? 'btn-disabled' : 'btn-enabled'}`}
              onClick={handleUndo}
              title="Undo last action (Ctrl+Z)"
              disabled={!canUndo}
            >
            Undo
            </button>
            <button
              className={`btn redo-btn ${!canRedo ? 'btn-disabled' : 'btn-enabled'}`}
              onClick={handleRedo}
              title="Redo last action (Ctrl+Y)"
              disabled={!canRedo}
            >
            Redo
            </button>
        {/* </div> */}
      </div>
    </>
  );
};

export default UndoRedoBar;
