import '../../styles/controls.css';
import React, { useContext, useState, useRef } from 'react';
import { GlobalContext } from '../../App';
import { SceneObject } from '../../constants/object';
import { createNewObject } from '../../utils/object';
import Slider from './Slider';
import ImportModal from '../../modals/ImportModal';
import useModal from '../../hooks/useModal';


const ObjectControl = () => {
  const { objects, setObjects, timeline, currentTime, setTimeline } =
    useContext(GlobalContext);

  const [selectedObject, setSelectedObject] = useState(null);
  const fileInputRef = useRef(null);

  const {
    isOpen: isImportModalOpen,
    openModal: openImportModal,
    closeModal: closeImportModal,
    Modal: ImportModalModal,
  } = useModal();

  const handleCreateNewObject = async () => {
    if (!fileInputRef.current || fileInputRef.current.files.length === 0) {
      window.alert('Please select an image file before creating a new object.');
      return;
    }

    openImportModal()

  };

  const handleCreateNewCharCallback =async(option)=> {
  
      const file = fileInputRef.current.files[0];
  
      try {
        await createNewObject({
          file,
          objects,
          setObjects,
          timeline,
          setTimeline,
          currentTime,
          option
        });
        fileInputRef.current.value = '';
        closeImportModal()
      } catch (error) {
        console.error('Failed to create new object:', error);
      }
    }

  const handleObjectSelect = (e) => {
    const selectedId = e.target.value;
    setSelectedObject(objects.find((obj) => obj.id === selectedId));
  };

  const onSliderChange = (e, sliderName) => {
    const newValue = parseFloat(e.target.value);
    selectedObject[sliderName].value = newValue;
    setSelectedObject({ ...selectedObject });
  };

  const setZIndex = (id) => {
    // Prompt the user for a new z-index
    const input = prompt('Please input Z index');
    if (!input) {
      return; // if no input, do nothing
    }

    const newZ = parseInt(input, 10);
    if (isNaN(newZ)) {
      alert('Invalid number input.');
      return;
    }

    // Check if any other object has this z-index
    const isTaken = objects.some((obj) => obj.id !== id && obj.zIndex === newZ);
    if (isTaken) {
      alert('This z-index is already taken by another object.');
      return;
    }

    // Update the target object's z-index
    const updatedObjects = objects.map((obj) => {
      if (obj.id === id) {
        const updatedObj = { ...obj, zIndex: newZ };
        // Restore the prototype to ensure class methods are available
        Object.setPrototypeOf(updatedObj, Object.getPrototypeOf(obj));
        return updatedObj;
      }
      return obj;
    });

    // Sort the objects by zIndex in ascending order
    updatedObjects.sort((a, b) => a.zIndex - b.zIndex);

    // Update state with the new array
    setObjects(updatedObjects);
  };

  return (
    <div className="flex-container column flex-one">
      <h4>Add an Object</h4>
      <div>
        <input type="file" ref={fileInputRef} />
        <button className="btn" onClick={handleCreateNewObject}>
          Create new object
        </button>
      </div>
      <div>
        {objects.map((value, index) => (
          <div key={value.id} className="character-row">
            {index + 1}: {value.name} - {value.id} - zIndex: {value.zIndex}
            <button className="btn" onClick={() => setZIndex(value.id)}>
              Set Z-index
            </button>
          </div>
        ))}
      </div>
      <div>
        <div className="sliders-control">
          <select onChange={handleObjectSelect} onClick={handleObjectSelect}>
            <option value="0">Please select object to manipulate</option>
            {objects.map((obj) => (
              <option key={obj.id} value={obj.id}>
                {obj.name} - {obj.id}
              </option>
            ))}
          </select>
          <div id="sliders">
            {selectedObject &&
              selectedObject.sliders.map((slider, index) => (
                <Slider
                  key={`${index}_slider`}
                  slider={slider}
                  handleInput={onSliderChange}
                />
              ))}
          </div>
        </div>
      </div>
      <ImportModalModal>
        <ImportModal callback={handleCreateNewCharCallback} />
      </ImportModalModal>
    </div>
  );
};

export default ObjectControl;
