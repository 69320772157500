// CharacterModal.js
import '../styles/controls.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from '../App';
import { RegularCharacter } from '../constants/regular';
import { delay } from '../utils/time';

const UploadCharImageModal = ({ uploadSideImageId }) => {
  const { characters, setCharacters } = useContext(GlobalContext);
  const [viewDirection, setViewDirection] = useState('front');
  const [selectedChar, setSelectedChar] = useState(null);
  const [renderImage, setRenderImage] = useState(null);

  useEffect(() => {
    const selectedChar = characters.find(
      (char) => char.id === uploadSideImageId
    );

    let imageToShow;
    switch (viewDirection) {
      case 'back':
        imageToShow = selectedChar.backImage;
        break;
      case 'left':
        imageToShow = selectedChar.leftImage;
        break;
      case 'right':
        imageToShow = selectedChar.rightImage;
        break;
      default:
        imageToShow = selectedChar.image; // front/default image
    }

    setRenderImage(imageToShow);
  }, [uploadSideImageId, viewDirection]);

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => setViewDirection('front')}>Front View</button>
        <button onClick={() => setViewDirection('back')}>Back View</button>
        <button onClick={() => setViewDirection('left')}>Left View</button>
        <button onClick={() => setViewDirection('right')}>Right View</button>
      </div>
      <div>
        {renderImage && (
          <img
            src={renderImage}
            alt={`Character ${viewDirection} view`}
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const imageData = e.target.result;
                // Update the appropriate view based on viewDirection
                switch (viewDirection) {
                  case 'back':
                    setCharacters((prevCharacters) =>
                      prevCharacters.map((char) => {
                        if (char.id === uploadSideImageId) {
                          const updatedChar = {
                            ...char,
                            backImage: imageData,
                            backImageUrl: file.name,
                          };
                          // Restore the prototype chain
                          Object.setPrototypeOf(
                            updatedChar,
                            RegularCharacter.prototype
                          );
                          return updatedChar;
                        }
                        return char;
                      })
                    );
                    break;
                  case 'left':
                    setCharacters((prevCharacters) =>
                      prevCharacters.map((char) => {
                        if (char.id === uploadSideImageId) {
                          const updatedChar = {
                            ...char,
                            leftImage: imageData,
                            leftImageUrl: file.name,
                          };
                          // Restore the prototype chain
                          Object.setPrototypeOf(
                            updatedChar,
                            RegularCharacter.prototype
                          );
                          return updatedChar;
                        }
                        return char;
                      })
                    );
                    break;
                  case 'right':
                    setCharacters((prevCharacters) =>
                      prevCharacters.map((char) => {
                        if (char.id === uploadSideImageId) {
                          const updatedChar = {
                            ...char,
                            rightImage: imageData,
                            rightImageUrl: file.name,
                          };
                          // Restore the prototype chain
                          Object.setPrototypeOf(
                            updatedChar,
                            RegularCharacter.prototype
                          );
                          return updatedChar;
                        }
                        return char;
                      })
                    );
                    break;
                  default:
                    setCharacters((prevCharacters) =>
                      prevCharacters.map((char) => {
                        if (char.id === uploadSideImageId) {
                          const updatedChar = {
                            ...char,
                            image: imageData,
                            backImageUrl: file.name,
                          };
                          // Restore the prototype chain
                          Object.setPrototypeOf(
                            updatedChar,
                            RegularCharacter.prototype
                          );
                          return updatedChar;
                        }
                        return char;
                      })
                    );
                }
              };
              reader.readAsDataURL(file);
            }
          }}
        />
        <p>Upload image for {viewDirection} view</p>
      </div>
    </div>
  );
};

export default UploadCharImageModal;
