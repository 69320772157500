import { SceneObject } from './object';

export class P5CustomObject extends SceneObject {
  constructor(offsetX, offsetY, id, name, code) {
    super(offsetX, offsetY, id, name);
    this.code = code;
    this.type = 'p5custom';
    this.particles = [];
    this.isInitialized = false;
    this.isObject = true;
    this.width = 200; // Increased size for better visibility
    this.height = 200; // Increased size for better visibility
    this.scale = 1;
    this.objectType = 'custom';
  }

  initialize(p5) {
    if (this.isInitialized) return;

    const sandbox = {
      random: p5.random.bind(p5),
      width: p5.width,
      height: p5.height,
    };

    try {
      const CustomParticle = new Function(
        'sandbox',
        `
                with (sandbox) {
                    return ${this.code}
                }
            `
      )(sandbox);

      this.ParticleClass = CustomParticle;
      this.isInitialized = true;
    } catch (error) {
      console.error('Error initializing P5 custom object:', error);
    }
  }

  drawObject(p5) {
    if (!this.isInitialized) {
      this.initialize(p5);
    }

    p5.push();

    // Create a graphics buffer for the particles
    const particleBuffer = p5.createGraphics(this.width, this.height);

    // Center the coordinate system in the buffer
    particleBuffer.translate(this.width / 2, this.height / 2);

    // Add new particles
    for (let i = 0; i < 5; i++) {
      if (this.ParticleClass) {
        this.particles.push(new this.ParticleClass(0, 0));
      }
    }

    // Update and show particles
    for (let i = this.particles.length - 1; i >= 0; i--) {
      const particle = this.particles[i];
      particle.update();
      particle.show(particleBuffer);

      if (particle.finished()) {
        this.particles.splice(i, 1);
      }
    }

    // Draw the buffer at the object's position with proper scaling
    p5.translate(this.offsetX, this.offsetY);
    p5.rotate(this.bX.value);
    const scaleX = this.bW.value / 100;
    const scaleY = this.bH.value / 100;
    p5.scale(scaleX, scaleY);
    p5.imageMode(p5.CENTER);
    p5.image(particleBuffer, 0, 0);

    p5.pop();

    particleBuffer.remove();
  }
}
