import '../../styles/controls.css';
import React, { useContext, useRef } from 'react';
import { GlobalContext } from '../../App';
import ImportModal from '../../modals/ImportModal';
import useModal from '../../hooks/useModal';

const BackgroundControl = () => {

  const { setBackgroundImage, setBackgroundImageUrl } =
    useContext(GlobalContext);
  const fileInputRef = useRef(null);

  const {
    isOpen: isImportModalOpen,
    openModal: openImportModal,
    closeModal: closeImportModal,
    Modal: ImportModalModal,
  } = useModal();

  const handleBackgroundChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) openImportModal()
    
  };

  const handleBackgroundChangeCallBack = async (option) => {
    const file = fileInputRef.current.files[0];
    if (!file) return;
  
    if (option === "base64") {
      const base64String = await getBase64(file);
      setBackgroundImage(base64String);
    } else {
      setBackgroundImage(file.name);
    }
    fileInputRef.current.value = '';
    closeImportModal();
  };
  
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  

  return (
    <div className="upload-section">
      <label>Upload Background:</label>
      <input
        type="file"
        id="background-upload"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleBackgroundChange}
      />
      <ImportModalModal>
        <ImportModal callback={handleBackgroundChangeCallBack} />
      </ImportModalModal>
    </div>
  );
};

export default BackgroundControl;
