import '../../styles/controls.css';
import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../App';
import {
  getCurrentStepIndex,
  getLastStep,
  getXStep,
  normalizeTimelineTimes,
} from '../../utils/timeline';
import { insertEmptyStepUtils } from '../../utils/timeline';


const TimelineControl = () => {
  const { timeline, setTimeline, currentTime, totalTime } =
    useContext(GlobalContext);
  const [stepIndex, setStepIndex] = useState('');

  const insertAtEnd = () => {
    if (timeline.length === 0) return;

    const lastStep = getLastStep(timeline);

    if (lastStep) {
      // Filter out actions where type is "audio"
      const filteredActions = lastStep.actions.filter(
        (action) => action.type !== 'audio'
      );

      // Create a new step with filtered actions
      const newStep = {
        ...lastStep,
        time: timeline[timeline.length - 1]?.time + 1 || 0,
        actions: filteredActions, // Use only non-audio actions
      };

      setTimeline([...timeline, newStep]);
    }
  };

  const insertNewStep = () => {
    if (!stepIndex || stepIndex < 0 || stepIndex > timeline.length) {
      console.warn('Invalid index');
      return;
    }

    const xStep = getXStep(timeline, stepIndex);

    if (!xStep) return;

    // Remove any actions where type is "audio"
    const filteredActions = xStep.actions.filter(
      (action) => action.type !== 'audio'
    );

    // Create a duplicate of the current step with filtered actions
    const newStep = { ...xStep, actions: filteredActions };

    // Insert at the specified index
    const newTimeline = [
      ...timeline.slice(0, stepIndex),
      newStep,
      ...timeline.slice(stepIndex),
    ];

    // Re-index the timeline steps
    const reIndexedTimeline = newTimeline.map((step, idx) => ({
      ...step,
      time: idx,
    }));

    setTimeline(reIndexedTimeline);
  };

  const insertEmptyStep = () => {
    const r = insertEmptyStepUtils(timeline)
    setTimeline(r);
  };

  const deleteThisStep = () => {
    // Get the current step index using currentTime
    const index = getCurrentStepIndex(timeline, currentTime);

    // Validate the index. If it is not valid, warn and exit.
    if (index < 0 || index >= timeline.length) {
      console.warn('Invalid step index for deletion');
      return;
    }

    // Make a shallow copy of the timeline array
    const updatedTimeline = [...timeline];

    // Remove the current step
    updatedTimeline.splice(index, 1);

    // Optionally re-index the timeline steps.
    // You can either use normalizeTimelineTimes if it adjusts the "time" property,
    // or map over the array to assign new time values.
    const reIndexedTimeline = normalizeTimelineTimes(updatedTimeline);
    // Alternatively, if you want to simply reassign sequential times:
    // const reIndexedTimeline = updatedTimeline.map((step, idx) => ({ ...step, time: idx }));

    // Update the timeline state
    setTimeline(reIndexedTimeline);
  };

  return (
    <>
      <div id="editBar" className="flex-container column">
        <h4> Insert new step </h4>
        <div>
          <button
            className="btn"
            type="button"
            onClick={insertEmptyStep}
            title="Insert empty step"
          >
            Insert empty step
          </button>
        </div>
        <div>
          <button
            className="btn"
            type="button"
            id="insertNewStep"
            onClick={insertNewStep}
            title="Insert new step at"
          >
            Insert step At
          </button>
          <input
            id="stepsInput"
            type="number"
            min="0"
            placeholder="Step"
            value={stepIndex}
            onChange={(e) => setStepIndex(parseInt(e.target.value, 10) || '')}
          />
        </div>

        <div>
          <button
            className="btn"
            type="button"
            id="delete"
            onClick={deleteThisStep}
            title="Delete the current selected Step"
          >
            Delete This Step
          </button>
        </div>

        <div>
          <button
            className="btn"
            type="button"
            id="insertAtEnd"
            onClick={insertAtEnd}
            title="Insert new step at the end"
          >
            Insert new step at End
          </button>
        </div>
      </div>
    </>
  );
};

export default TimelineControl;
