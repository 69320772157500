import '../styles/controls.css';
import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { GlobalContext } from '../App';
import {
  extractUniqueTargets,
  getCurrentStep,
  getCurrentStepIndex,
  normalizeTimelineTimes,
} from '../utils/timeline';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';
import ImportModal from '../modals/ImportModal';
import useModal from '../hooks/useModal';

const Audioline = () => {
  const {
    characters,
    timeline,
    setTimeline,
    totalTime,
    currentTime,
    setCurrentTime,
    dialogs,
    setDialogs,
    notify
  } = useContext(GlobalContext);
  const [selectedCharacter, setSelectedCharacter] = useState(
    'background_not_attached'
  );
  const [stepCharacters, setStepCharacters] = useState([]);
  const [selectedAudioUrl, setSelectedAudioUrl] = useState(0); // id of audio

  const audioRef = useRef();
  const fileInputRef = useRef(null);

  // Add new state for tracking currently playing audio
  const [playingAudio, setPlayingAudio] = useState(null);
  const [triggerReload, setTriggerReload] = useState(false)
  const audioElementRef = useRef(null);

  const {
    isOpen: isImportModalOpen,
    openModal: openImportModal,
    closeModal: closeImportModal,
    Modal: ImportModalModal,
  } = useModal();

  function handleAudioUpload(event) {
    const file = event.target.files[0];
    if (!file) return;

    openImportModal()
  }

  const handleAudioUploadCallback=async(option)=>{
    const file = fileInputRef.current.files[0];
      
    try {

      const reader = new FileReader();
      reader.onload = (e) => {
        const tempAudio = new Audio(e.target.result);
        tempAudio.addEventListener('loadedmetadata', () => {
  
  
          const newAudio = {
            id: file.name,
            title: file.name,
            asset: option === "base64" ? e.target.result : file.name,
            duration: tempAudio.duration,
          }
          console.log("newAudio", newAudio)
  
          const exists = dialogs.some(audio => audio.id === newAudio.id);
  
          if (!exists) {
            dialogs.push(newAudio);
            notify("Audio uploaded to dialog")
            // Clear the file input value and reset audio state after successful insertion
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setDialogs(dialogs);
            setTriggerReload((triggerReload)=>!triggerReload)
          } else {
            console.log("Audio with the same ID already exists");
          }
  
        });
      };
      reader.readAsDataURL(file);


      fileInputRef.current.value = '';
      closeImportModal()
    } catch (error) {
      console.error('Failed to create new object:', error);
    }
  }

  // Debounced function for handling currentTime updates
  const debouncedCurrentTimeUpdate = debounce(() => {
    const currentStep = getCurrentStep(timeline, currentTime);
    if (currentStep) {
      const actions = currentStep.actions;
      const uniqueChars = extractUniqueTargets(actions);
      setStepCharacters(uniqueChars);
    }
  }, 300);

  useEffect(() => {
    debouncedCurrentTimeUpdate();
    return () => debouncedCurrentTimeUpdate.cancel();
  }, [currentTime, characters, timeline]);
  const insertAudio = async () => {
    if (!selectedCharacter) {
      alert('Please select a character.');
      return;
    }
  
    // Find the index of the current step
    const stepIndex = getCurrentStepIndex(timeline, currentTime);
    if (stepIndex === -1) return;
    // Check if there are any actions at the current step
    if (
      !timeline[stepIndex]?.actions ||
      timeline[stepIndex].actions.length === 0
    ) {
      alert('No actions found at the current step. Please add actions first.');
      return;
    }
    // Clone the timeline array to avoid direct mutation
    const updatedTimeline = [...timeline];
  
    console.log("selectedAudioUrl", selectedAudioUrl, dialogs);
  
    const selectedAudio = selectedAudioUrl == 0 ? dialogs[0]
      : dialogs.find(audio => audio.id == selectedAudioUrl);
  
    console.log("selectedAudio", selectedAudio);
  
    // If duration doesn't exist, calculate it using the "seek trick" for Infinity duration
    let duration = selectedAudio?.duration;
    if (!duration) {
      try {
        duration = await new Promise((resolve, reject) => {
          const tempAudio = new Audio(selectedAudio ? selectedAudio.asset : dialogs[0].asset);
          tempAudio.preload = 'metadata';
          tempAudio.addEventListener('loadedmetadata', () => {
            console.log("tempAudio.duration", tempAudio.duration)
            if (tempAudio.duration === Infinity) {
              // Use the seek trick to get the actual duration
              tempAudio.currentTime = Number.MAX_SAFE_INTEGER;
              tempAudio.ontimeupdate = () => {
                tempAudio.ontimeupdate = null;
                tempAudio.currentTime = 0; // reset back after updating duration
                resolve(tempAudio.duration);
              };
            } else {
              resolve(tempAudio.duration);
            }
          });
          tempAudio.addEventListener('error', (error) => {
            reject(error);
          });
        });
      } catch (error) {
        console.error('Failed to get audio duration:', error);
        duration = 1; // fallback duration
      }
    }

    console.log("duration final", duration)
  
    const newAudioAction = {
      target: selectedCharacter,
      type: 'audio',
      parameters: {
        id: selectedAudio?.id || '',
        duration: duration,
        uuid: uuidv4()
      },
    };
  
    // Add new audio action to the current step's actions array
    updatedTimeline[stepIndex] = {
      ...updatedTimeline[stepIndex],
      actions: [...updatedTimeline[stepIndex].actions, newAudioAction],
    };
  
    const normalizedTimeline = normalizeTimelineTimes(updatedTimeline); // recalculate time properties due to change in durations
  
    // Update state with new timeline
    setTimeline(normalizedTimeline);
  
    setCurrentTime(timeline[stepIndex].time); // reset currentTime due to reshuffled timeline
  
    // Alert with the audio file's name
    alert(`audio inserted`);
  };
  

  const removeAllAudio = () => {
    // Find the index of the current step
    const stepIndex = getCurrentStepIndex(timeline, currentTime);
    if (stepIndex === -1) return;

    // Clone the timeline array to avoid direct mutation
    const updatedTimeline = [...timeline];

    // Filter out all "audio" type actions
    updatedTimeline[stepIndex] = {
      ...updatedTimeline[stepIndex],
      actions: updatedTimeline[stepIndex].actions.filter(
        (action) => action.type !== 'audio'
      ),
    };

    const normalizedTimeline = normalizeTimelineTimes(updatedTimeline);

    // Update state with new timeline
    setTimeline(normalizedTimeline);
  };

  // Function to play a specific audio action
  const playAudio = (audioAction) => {
    console.log('audioAction', audioAction, playingAudio);
    if (playingAudio) {
      // Stop currently playing audio
      playingAudio.pause();
      playingAudio.currentTime = 0;
    }

    const audioToPlay = dialogs.find(audio => audio.id == audioAction.parameters.id);

    console.log("audioToPlay", audioToPlay)

    const audio = new Audio(audioToPlay.asset);

    audio.onended = () => {
      setPlayingAudio(null);
    };
    audio.play();
    setPlayingAudio(audio);
  };

  // Function to stop currently playing audio
  const stopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
      setPlayingAudio(null);
    }
  };

  const clearFileInput = () => {
    fileInputRef.current.value = '';
  };

  // Get current step's audio actions
  const currentStepAudioActions = useMemo(() => {
    const currentStep = getCurrentStep(timeline, currentTime);
    if (!currentStep) return [];
    return currentStep.actions.filter((action) => action.type === 'audio');
  }, [timeline, currentTime]);


  return (
    <>
      <div id="editBar" className="flex-container column">
        <h4>Insert audio at current step</h4>
        {/* <LoadAudioFromURL setSelectedAudioUrl={setSelectedAudioUrl} selectedAudioUrl={selectedAudioUrl} setAudioDuration={setAudioDuration} /> */}
        <div className="w-fit" style={{ width: "fit-content" }}>
          <select
            value={selectedAudioUrl}
            onChange={(e) => setSelectedAudioUrl(e.target.value)}
            className=""
          >
            {dialogs &&
              dialogs.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.id}{value.text ? ` - ${value.text}` : ''}
                </option>
              ))}
          </select>
        </div>
        <div>
          Current Step: {getCurrentStepIndex(timeline, currentTime) + 1}
          <div>
            <label>Upload Audio:</label>
            <input
              type="file"
              id="audio-upload"
              accept=".mp3"
              onChange={handleAudioUpload}
              ref={fileInputRef} // Attach the ref here
            />
            <button onClick={clearFileInput}>Clear file input</button>
          </div>
          <div>
            <label>Select Characters:</label>
            <select
              value={selectedCharacter}
              onChange={(e) => setSelectedCharacter(e.target.value)}
            >
              <option value="background_not_attached">
                Background Not attached to any character
              </option>
              {stepCharacters.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn"
            type="button"
            onClick={insertAudio}
            title="Insert audio into the current step"
          >
            Insert Audio at Current Step
          </button>
          <div>
            <button
              className="btn"
              type="button"
              onClick={removeAllAudio}
              title="Remove all Audio at current step"
            >
              Remove All Audio at Current Step
            </button>
          </div>
        </div>

        {/* Add new section for current audio actions */}
        {currentStepAudioActions.length > 0 && (
          <div className="audio-controls">
            <h4>Current Step Audio</h4>
            {currentStepAudioActions.map((audioAction, index) => (
              <div key={audioAction.parameters.uuid} className="audio-row">
                <span className="audio-label">
                  {audioAction.target} - {index + 1}
                </span>
                <button
                  className="audio-play-btn"
                  onClick={() => playAudio(audioAction)}
                  disabled={!!playingAudio}
                >
                  {playingAudio ? 'Playing...' : 'Play'}
                </button>
                {playingAudio && (
                  <button className="audio-play-btn" onClick={stopAudio}>
                    Stop
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <ImportModalModal>
        <ImportModal callback={handleAudioUploadCallback} />
      </ImportModalModal>
    </>
  );
};

export default Audioline;
