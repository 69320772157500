export function loadAsset(source, p5, callback, type = 'image') {
  if (!p5 || typeof p5.loadImage !== 'function') {
    console.error('Invalid p5 instance provided:', p5);
    return null;
  }
  if (type === 'image') {
      try {
        const img = p5.loadImage(
          source,
          (loadedImage) => {
            if (callback !== null && callback !== undefined) {
              callback(loadedImage);
            }
          },
          (err) => {
            console.error('Error loading image:', err);
          }
        );
        return img;
      } catch (error) {
        console.error('Error in loadAsset:', error);
        return null;
      }

  } else if (type === 'sound') {
    if (isUUID(source)) {
      return source; // load from db, to do later
    } else if (isFilePath(source) || isURL(source) || isBase64(source)) {
      try {
        const soundAsset = p5.loadSound(source, (loadedSound) => {
          if (callback !== null && callback !== undefined) {
            callback(loadedSound);
          }
        });
        return soundAsset;
      } catch (error) {
        console.error('Error in loadAsset:', error);
        return null;
      }
    } else {
      throw new Error('Unsupported asset type for sound');
    }
  } else {
    throw new Error('Unsupported asset type provided: ' + type);
  }
}

function isUUID(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

function isFilePath(path) {
  if (typeof path !== 'string') return false;
  if (isURL(path)) return false;
  const windowsRegex = /^[a-zA-Z]:[\/](?:[^\/:*?"<>|\r\n]+[\/]?)*$/;
  const unixRegex = /^(\/|~\/)(?:[^\/\0]+\/?)*$/;
  const relativeRegex = /^(?:[\w\-. ]+[\\\/]?)+$/;
  return (
    windowsRegex.test(path) || unixRegex.test(path) || relativeRegex.test(path)
  );
}

function fetchFromURL(source) {
  return source;
}

function isURL(str) {
  if (typeof str !== 'string') return false;
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

function isBase64(str) {
  if (typeof str !== 'string') {
    return false;
  }
  str = str.trim();
  if (str.length % 4 !== 0) {
    return false;
  }
  const base64regex =
    /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}(?:==)|[A-Za-z0-9+\/]{3}=)?$/;
  return base64regex.test(str);
}
