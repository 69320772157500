import '../../styles/controls.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from '../../App';
import { RegularCharacter } from '../../constants/regular';
import { v4 as uuidv4 } from 'uuid';
import Slider from './Slider';
import {
  getCurrentStepIndex,
  normalizeTimelineTimes,
} from '../../utils/timeline';
import {
  createNewChar,
  updateCharacterState,
  updateTimelineAction,
  uploadImage,
  setCharacterView,
  exportCharacterUtils,
} from '../../utils/character';
import useModal from '../../hooks/useModal';
import EyeSocketModal from '../../modals/EyeSocketModal';
import UploadCharImageModal from '../../modals/UploadCharImageModal';
import ImportModal from '../../modals/ImportModal';

const RegularCharacterControl = () => {

  const {
    characters,
    setCharacters,
    timeline,
    currentTime,
    setTimeline,
    notify,
  } = useContext(GlobalContext);
  const [selectedChar, setSelectedChar] = useState(null);
  
  // all the modals
  const { isOpen, openModal, closeModal, Modal } = useModal();
  const {
    isOpen: isEyeSocketExEyeLidOpen,
    openModal: openEyeSocketExEyeLidModal,
    closeModal: closeEyeSocketExEyelidModal,
    Modal: EyeSocketExEyelidModal,
  } = useModal();
  const [drawEyeSocketCharId, setDrawEyeSocketCharId] = useState(null);
  const [drawEyeSocketExEyelidCharId, setDrawEyeSocketExEyelidCharId] =
    useState(null);
  const {
    isOpen: isOpenUpload,
    openModal: openUploadModal,
    closeModal: closeUploadModal,
    Modal: UploadImageModal,
  } = useModal();
  const {
    isOpen: isImportModalOpen,
    openModal: openImportModal,
    closeModal: closeImportModal,
    Modal: ImportModalModal,
  } = useModal();
  
  const [uploadSideImageId, setUploadSideImageId] = useState(null);

  // Create a ref for the file input element.
  const fileInputRef = useRef(null);
  const eyeLidImageRef = useRef(null);
  const eyeBallImageRef = useRef(null);

  const handleCreateNewChar = async () => {
    if (!fileInputRef.current || fileInputRef.current.files.length === 0) {
      window.alert(
        'Please select an image file before creating a new character.'
      );
      return;
    }
    openImportModal()
  };

  const handleCreateNewCharCallback =async(option)=> {

    const file = fileInputRef.current.files[0];

    try {
      await createNewChar({
        file,
        characters,
        setCharacters,
        timeline,
        setTimeline,
        currentTime,
        option
      });

      // Optionally, reset the file input after successful creation
      fileInputRef.current.value = '';
      closeImportModal()
    } catch (error) {
      console.error('Failed to create new character:', error);
    }
  }

  const handleCharSelect = (e) => {
    const selectedChar = e.target.value;
    setSelectedChar(characters.find((char) => char.id === selectedChar));
  };

  const onSliderChange = (e, sliderName) => {
    const newValue = parseFloat(e.target.value);
    const updatedSliders = selectedChar.sliders.map((slider) =>
      slider.name === sliderName ? { ...slider, value: newValue } : slider
    );
    selectedChar[sliderName].value = newValue;
    setSelectedChar({ ...selectedChar, sliders: updatedSliders });
  };

  const toggleMouth = () => {

    setCharacters(
      updateCharacterState(
        characters,
        selectedChar.id,
        'talking',
        !selectedChar.talking
      )
    );
    setSelectedChar(
      updateCharacterState(
        [selectedChar],
        selectedChar.id,
        'talking',
        !selectedChar.talking
      )[0]
    );
  };

  const setZIndex = (id) => {
    // Prompt the user for a new z-index
    const input = prompt('Please input Z index');
    if (!input) {
      return; // if no input, do nothing
    }

    const newZ = parseInt(input, 10);
    if (isNaN(newZ)) {
      alert('Invalid number input.');
      return;
    }

    // Check if any other character (excluding the one with the given id) has this z-index
    const isTaken = characters.some(
      (char) => char.id !== id && char.zIndex === newZ
    );
    if (isTaken) {
      alert('This z-index is already taken by another character.');
      return;
    }

    // Update the target character's z-index and restore its prototype if needed
    const updatedCharacters = characters.map((char) => {
      if (char.id === id) {
        const updatedChar = { ...char, zIndex: newZ };
        // Restore the prototype to ensure class methods are available.
        Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
        return updatedChar;
      }
      return char;
    });

    // Sort the characters by zIndex in ascending order.
    updatedCharacters.sort((a, b) => a.zIndex - b.zIndex);

    // Update state with the new array.
    setCharacters(updatedCharacters);
  };

  const setEyeSocketArea = (id) => {
    setDrawEyeSocketCharId(id);
    openModal();
  };

  const setEyeSocketExEyelidArea = (id) => {
    setDrawEyeSocketExEyelidCharId(id);
    openEyeSocketExEyeLidModal();
  };

  const toggleEyes = () => {

    setCharacters(
      updateCharacterState(
        characters,
        selectedChar.id,
        'showEyes',
        !selectedChar.showEyes
      )
    );
    setSelectedChar(
      updateCharacterState(
        [selectedChar],
        selectedChar.id,
        'showEyes',
        !selectedChar.showEyes
      )[0]
    );
  };

  const addToCurrentStep = (id) => {
    let posX = 50;
    let posY = 50;
  
    // Loop through the characters array to find a matching character by id
    for (let i = 0; i < characters.length; i++) {
      if (characters[i].id === id) {
        posX = characters[i].offsetX;
        posY = characters[i].offsetY;
        break;
      }
    }
  
    const newAction = {
      type: 'move',
      parameters: {
        position: { x: posX, y: posY },
        duration: 1,
        easing: 'linear',
      },
    };
  
    setTimeline(updateTimelineAction(timeline, currentTime, id, newAction));
  };
  

  const toggleEyeMovement = (id) => {

    const updatedTimeline = updateTimelineAction(timeline, currentTime, id, {
      moveEye:
        !timeline[getCurrentStepIndex(timeline, currentTime)].actions.find(
          (a) => a.target === id
        )?.moveEye ?? true,
    });
    setTimeline(updatedTimeline);
    notify(
      `Eye movement ${updatedTimeline[getCurrentStepIndex(timeline, currentTime)].actions.find((a) => a.target === id).moveEye ? 'enabled' : 'disabled'} for character ${id}`
    );
  };

  const setEyePos = (id, pos) => {
    // Get the index of the current step using the provided timeline and currentTime.
    const currentStepIndex = getCurrentStepIndex(timeline, currentTime);
    if (currentStepIndex < 0) {
      alert('No current step found.');
      return;
    }

    const currentStep = timeline[currentStepIndex];

    // Ensure the current step has actions.
    if (!currentStep.actions || currentStep.actions.length === 0) {
      alert('No actions found in the current step.');
      return;
    }

    // Update the action that matches the provided character id.
    let actionFound = false;
    const updatedActions = currentStep.actions.map((action) => {
      if (action.target === id) {
        actionFound = true;
        // Convert "down" to "bottom" if necessary.
        const direction = pos === 'down' ? 'bottom' : pos;
        return {
          ...action,
          eyeDirection: direction,
        };
      }
      return action;
    });

    if (!actionFound) {
      alert(`No action found for character ${id} in the current step.`);
      return;
    }

    // Create an updated step with the modified actions.
    const updatedStep = {
      ...currentStep,
      actions: updatedActions,
    };

    // Update the timeline with the modified step.
    const updatedTimeline = [...timeline];
    updatedTimeline[currentStepIndex] = updatedStep;
    setTimeline(updatedTimeline);

    notify(`Eye direction set to ${pos} for character ${id}`);
  };

  const exportCharacter = (id) => {

    exportCharacterUtils(characters, id, notify);
  };

  const uploadEyeLid = (id) => {
    uploadImage(eyeLidImageRef, id, 'eyeLidImage', setCharacters, notify);
  };

  const uploadEyeBall = (id) => {
    uploadImage(eyeBallImageRef, id, 'eyeBallImage', setCharacters, notify);
  };

  const uploadSideImages = (id) => {
    setUploadSideImageId(id);
    openUploadModal();
  };

  const setLeftView = (id) => {
    setCharacterView(timeline, currentTime, id, 'left', setTimeline, notify);
  };

  const setRightView = (id) => {
    setCharacterView(timeline, currentTime, id, 'right', setTimeline, notify);
  };

  const setBackView = (id) => {
    setCharacterView(timeline, currentTime, id, 'back', setTimeline, notify);
  };

  const setFrontView = (id) => {
    setCharacterView(timeline, currentTime, id, 'front', setTimeline, notify);
  };

  return (
    <div id="" className="flex-container column flex-one">
      <h4>Characters</h4>
      {/* <LoadCharFromURL setCharacters={setCharacters} /> */}
      <label htmlFor="eyeLidImage">Eyelid Image:</label>
      <input
        type="file"
        id="eyeLidImage"
        ref={eyeLidImageRef}
        accept="image/*"
      />

      <label htmlFor="eyeBallImage">Eyeball Image:</label>
      <input
        type="file"
        id="eyeBallImage"
        ref={eyeBallImageRef}
        accept="image/*"
      />
      <div>
        {characters.map((value, index) => (
          <div key={value.id}>
            <span>
              {index + 1}: {value.name} - {value.id} - zIndex:{' '}
              <span className="zIndexLabel">{value.zIndex}</span>
            </span>
            <button onClick={() => setZIndex(value.id)}>Set Z-index</button>
            <button onClick={() => exportCharacter(value.id)}>
              Export Char
            </button>
            <button onClick={() => setEyeSocketArea(value.id)}>
              Set Eye Socket Area
            </button>
            <button onClick={() => setEyeSocketExEyelidArea(value.id)}>
              Set Eye Socket Ex EyeLid Area
            </button>
            <button onClick={() => uploadSideImages(value.id)}>
              Upload side and back Images
            </button>
            <button onClick={() => addToCurrentStep(value.id)}>
              Add to Current Step
            </button>
            <button onClick={() => toggleEyeMovement(value.id)}>
              Enable/Disable eye movement in this step
            </button>
            <button onClick={() => setEyePos(value.id, 'left')}>
              set Eyeball to Left
            </button>
            <button onClick={() => setEyePos(value.id, 'right')}>
              set Eyeball to Right
            </button>
            <button onClick={() => setEyePos(value.id, 'top')}>
              set Eyeball to Top
            </button>
            <button onClick={() => setEyePos(value.id, 'down')}>
              set Eyeball to Down
            </button>
            <button onClick={() => setEyePos(value.id, 'centre')}>
              set Eyeball to Centre
            </button>

            <button onClick={() => uploadEyeLid(value.id)}>
              upload Eyelid image
            </button>
            <button onClick={() => uploadEyeBall(value.id)}>
              upload EyeBall image
            </button>
            <button onClick={() => setFrontView(value.id)}>
              show Front side
            </button>
            <button onClick={() => setLeftView(value.id)}>
              show Left side
            </button>
            <button onClick={() => setRightView(value.id)}>
              show Right side
            </button>
            <button onClick={() => setBackView(value.id)}>
              show Back side
            </button>
          </div>
        ))}
      </div>
      <h4>Add a Regular Character</h4>
      <div>
        <input type="file" ref={fileInputRef} id="charInput" />
        <button
          className="btn"
          id="insert_custom_char"
          onClick={handleCreateNewChar}
        >
          Create new regular character
        </button>
      </div>
      <div>
        <div className="sliders-control">
          <select
            id="regular-select"
            onChange={handleCharSelect}
            onClick={handleCharSelect}
          >
            <option value="0">Please select char to manipulate</option>
            {characters.map((value) => (
              <option key={value.id} value={value.id}>
                {value.name} - {value.id}
              </option>
            ))}
          </select>
          <div id="sliders">
            {selectedChar &&
              selectedChar.sliders.map((slider, index) => (
                <Slider
                  key={`${index}_slider`}
                  slider={slider}
                  selectedChar={selectedChar}
                  handleInput={onSliderChange}
                />
              ))}
          </div>
          {selectedChar != null && (
            <div>
              <button className="btn" onClick={toggleMouth}>
                Show/Hide Mouth
              </button>
              <button className="btn" onClick={toggleEyes}>
                Show/Hide Eyes
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal>
        <EyeSocketModal
          drawEyeSocketCharId={drawEyeSocketCharId}
          eyeSocketsVarName="eyeSockets"
        />
      </Modal>
      <EyeSocketExEyelidModal>
        <EyeSocketModal
          drawEyeSocketCharId={drawEyeSocketExEyelidCharId}
          eyeSocketsVarName="eyeSocketExLid"
        />
      </EyeSocketExEyelidModal>
      <UploadImageModal>
        <UploadCharImageModal uploadSideImageId={uploadSideImageId} />
      </UploadImageModal>
      <ImportModalModal>
        <ImportModal callback={handleCreateNewCharCallback} />
      </ImportModalModal>

    </div>
  );
};

export default RegularCharacterControl;
