import '../styles/controls.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from '../App';


const ImportModal = ({ callback }) => {
  const { timeline, characters, backgroundImage, objects, dialogs } =
    useContext(GlobalContext);

  const [importOption, setImportOption] = useState('base64'); // State to track the selected export option

  const importAs = async () => {
    callback(importOption)
  };

  return (
    <div>
      <h2>Import Option</h2>
      <p></p>

      <div>
        <label>
          <input
            type="radio"
            value="base64"
            checked={importOption === 'base64'}
            onChange={() => setImportOption('base64')}
          />
          Import as Base64
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            value="fileReference"
            checked={importOption === 'fileReference'}
            onChange={() => setImportOption('fileReference')}
          />
          Import as File Reference
        </label>
      </div>

      <button onClick={importAs}>Import</button>
    </div>
  );
};

export default ImportModal;
