// CharacterModal.js
import '../styles/controls.css';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from '../App';
import { RegularCharacter } from '../constants/regular';
import { delay } from '../utils/time';

const EyeSocketModal = ({ drawEyeSocketCharId, eyeSocketsVarName }) => {
  const { characters, setCharacters } = useContext(GlobalContext);
  const canvasRef = useRef(null);
  const imageRef = useRef(null); // Store the loaded image
  const [selectedChar, setSelectedChar] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  // currentPath holds the points for the stroke currently being drawn.
  const [currentPath, setCurrentPath] = useState([]);
  // Each eye socket is a single stroke: an array of points.
  const [eyeSockets, setEyeSockets] = useState({ left: [], right: [] });
  const [drawingMode, setDrawingMode] = useState('left'); // 'left' or 'right'

  // New state for zoom & panning
  const [zoom, setZoom] = useState(1);
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [interactionMode, setInteractionMode] = useState('draw'); // "draw" or "pan"
  const [isPanning, setIsPanning] = useState(false);
  const [panStart, setPanStart] = useState({ x: 0, y: 0 });

  // Helper: Calculate image position/size on the canvas with zoom and pan offset.
  const getImageDrawParams = () => {
    const canvas = canvasRef.current;
    const img = imageRef.current;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const imgAspectRatio = img.width / img.height;
    const canvasAspectRatio = canvasWidth / canvasHeight;
    let baseDrawWidth = canvasWidth;
    let baseDrawHeight = canvasHeight;
    if (imgAspectRatio > canvasAspectRatio) {
      // Image is wider than canvas; base on canvas width.
      baseDrawHeight = canvasWidth / imgAspectRatio;
    } else {
      // Image is taller than canvas.
      baseDrawWidth = canvasHeight * imgAspectRatio;
    }
    // Apply zoom factor.
    const drawWidth = baseDrawWidth * zoom;
    const drawHeight = baseDrawHeight * zoom;
    // Center the image and add pan offsets.
    const x = (canvasWidth - drawWidth) / 2 + panOffset.x;
    const y = (canvasHeight - drawHeight) / 2 + panOffset.y;
    return { x, y, drawWidth, drawHeight };
  };

  // Load image and set up character when drawEyeSocketCharId changes.
  useEffect(() => {
    if (drawEyeSocketCharId) {
      const char = characters.find((char) => char.id === drawEyeSocketCharId);
      if (char) {
        // Set up the character with its prototype.
        const charWithPrototype = { ...char };
        Object.setPrototypeOf(charWithPrototype, RegularCharacter.prototype);
        setSelectedChar(charWithPrototype);

        // Initialize eyeSockets from the character if available.
        console.log('char[eyeSocketsVarName]', char[eyeSocketsVarName]);
        setEyeSockets(char[eyeSocketsVarName] || { left: [], right: [] });

        // Reset zoom, pan, and interaction mode.
        setZoom(1);
        setPanOffset({ x: 0, y: 0 });
        setInteractionMode('draw');

        // Load image if available.
        if (char.image) {
          const img = new Image();
          img.onload = () => {
            imageRef.current = img;
            drawImage();
          };
          img.src = char.image;
        }
      }
    }
  }, [drawEyeSocketCharId]);

  const drawImage = async () => {
    // await delay(1500)
    if (canvasRef.current && imageRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = imageRef.current;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const { x, y, drawWidth, drawHeight } = getImageDrawParams();

      ctx.drawImage(img, x, y, drawWidth, drawHeight);

      // Draw the saved stroke (if any) for the current eye.
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      let savedStroke = eyeSockets.left;
      if (drawingMode === 'right') {
        savedStroke = eyeSockets.right;
      }
      if (savedStroke.length > 0) {
        ctx.beginPath();
        const firstPoint = savedStroke[0];
        ctx.moveTo(
          x + (firstPoint.x / 100) * drawWidth,
          y + (firstPoint.y / 100) * drawHeight
        );
        savedStroke.forEach((point) => {
          ctx.lineTo(
            x + (point.x / 100) * drawWidth,
            y + (point.y / 100) * drawHeight
          );
        });
        ctx.closePath();
        ctx.stroke();
      }

      // Draw the current in-progress stroke.
      if (currentPath.length > 0) {
        ctx.beginPath();
        const firstPoint = currentPath[0];
        ctx.moveTo(
          x + (firstPoint.x / 100) * drawWidth,
          y + (firstPoint.y / 100) * drawHeight
        );
        currentPath.forEach((point) => {
          ctx.lineTo(
            x + (point.x / 100) * drawWidth,
            y + (point.y / 100) * drawHeight
          );
        });
        ctx.stroke();
      }
    }
  };

  // Redraw whenever currentPath, eyeSockets, zoom, or panOffset change.
  useEffect(() => {
    if (imageRef.current) {
      drawImage();
    }
  }, [currentPath, eyeSockets, zoom, panOffset]);

  // EVENT HANDLERS

  const handleCanvasMouseDown = (e) => {
    if (!imageRef.current) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (interactionMode === 'pan') {
      setIsPanning(true);
      setPanStart({ x: e.clientX, y: e.clientY });
    } else {
      // Start a new stroke in drawing mode.
      const rawX = e.clientX - rect.left;
      const rawY = e.clientY - rect.top;
      const { x, y, drawWidth, drawHeight } = getImageDrawParams();
      const normX = ((rawX - x) / drawWidth) * 100;
      const normY = ((rawY - y) / drawHeight) * 100;
      setIsDrawing(true);
      setCurrentPath([{ x: normX, y: normY }]);
    }
  };

  const handleCanvasMouseMove = (e) => {
    if (!imageRef.current) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (interactionMode === 'pan' && isPanning) {
      // Update pan offset.
      const deltaX = e.clientX - panStart.x;
      const deltaY = e.clientY - panStart.y;
      setPanOffset((prev) => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
      setPanStart({ x: e.clientX, y: e.clientY });
    } else if (interactionMode === 'draw' && isDrawing) {
      const rawX = e.clientX - rect.left;
      const rawY = e.clientY - rect.top;
      const { x, y, drawWidth, drawHeight } = getImageDrawParams();
      const normX = ((rawX - x) / drawWidth) * 100;
      const normY = ((rawY - y) / drawHeight) * 100;
      setCurrentPath((prev) => [...prev, { x: normX, y: normY }]);
    }
  };

  const handleCanvasMouseUp = () => {
    if (interactionMode === 'pan') {
      setIsPanning(false);
    } else if (interactionMode === 'draw' && currentPath.length > 0) {
      // Save the completed stroke.
      setEyeSockets((prev) => ({
        ...prev,
        [drawingMode]: currentPath,
      }));
      setIsDrawing(false);
      setCurrentPath([]);
    }
  };

  // NEW: Undo function simply clears the stroke for the current eye.
  const handleUndo = () => {
    if (isDrawing) {
      // Clear the in-progress stroke.
      setCurrentPath([]);
      setIsDrawing(false);
    } else {
      // Clear the saved stroke for the current eye.
      setEyeSockets((prev) => ({ ...prev, [drawingMode]: [] }));
    }
  };

  // Save function (preserving the image property).
  const saveEyeSockets = () => {
    if (selectedChar) {
      const updatedEyeSockets = {
        left:
          eyeSockets.left.length > 0
            ? eyeSockets.left
            : selectedChar[eyeSocketsVarName]?.left || [],
        right:
          eyeSockets.right.length > 0
            ? eyeSockets.right
            : selectedChar[eyeSocketsVarName]?.right || [],
      };

      const updatedChar = {
        ...selectedChar,
        [eyeSocketsVarName]: updatedEyeSockets,
        image: selectedChar.image,
      };
      Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);

      setCharacters(
        characters.map((char) => {
          if (char.id === selectedChar.id) {
            const charWithPrototype = {
              ...updatedChar,
              image: selectedChar.image,
            };
            Object.setPrototypeOf(
              charWithPrototype,
              RegularCharacter.prototype
            );
            return charWithPrototype;
          }
          return char;
        })
      );
    }
  };

  // Enable Undo if either an in-progress stroke exists or a saved stroke exists for the current eye.
  const isUndoEnabled =
    (isDrawing && currentPath.length > 0) ||
    (!isDrawing && eyeSockets[drawingMode].length > 0);

  return (
    <div
      className="eye-socket-modal"
      style={{
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        width: '600px',
      }}
    >
      <h2>Draw Eye Socket Areas for {selectedChar?.name}</h2>

      <div style={{ marginBottom: '10px', textAlign: 'center' }}>
        <button
          onClick={() => setDrawingMode('left')}
          style={{
            backgroundColor: drawingMode === 'left' ? '#007bff' : '#ccc',
            color: 'white',
            margin: '0 10px',
          }}
          disabled={interactionMode !== 'draw'}
        >
          Draw Left Eye Socket
        </button>
        <button
          onClick={() => setDrawingMode('right')}
          style={{
            backgroundColor: drawingMode === 'right' ? '#007bff' : '#ccc',
            color: 'white',
            margin: '0 10px',
          }}
          disabled={interactionMode !== 'draw'}
        >
          Draw Right Eye Socket
        </button>
      </div>

      {/* Interaction Mode Controls */}
      <div style={{ marginBottom: '10px', textAlign: 'center' }}>
        <button
          onClick={() => setInteractionMode('draw')}
          style={{
            backgroundColor: interactionMode === 'draw' ? '#007bff' : '#ccc',
            color: 'white',
            margin: '0 10px',
          }}
        >
          Draw Mode
        </button>
        <button
          onClick={() => setInteractionMode('pan')}
          style={{
            backgroundColor: interactionMode === 'pan' ? '#007bff' : '#ccc',
            color: 'white',
            margin: '0 10px',
          }}
        >
          Pan Mode
        </button>
      </div>

      {/* Zoom Controls */}
      <div style={{ marginBottom: '10px', textAlign: 'center' }}>
        <button
          onClick={() => setZoom((prevZoom) => prevZoom * 1.1)}
          style={{ marginRight: '10px' }}
        >
          Zoom In
        </button>
        <button
          onClick={() => setZoom((prevZoom) => prevZoom / 1.1)}
          style={{ marginRight: '10px' }}
        >
          Zoom Out
        </button>
        <span>Zoom: {(zoom * 100).toFixed(0)}%</span>
      </div>

      <canvas
        ref={canvasRef}
        width={500}
        height={500}
        style={{
          border: '1px solid #ccc',
          display: 'block',
          margin: '20px auto',
          backgroundColor: '#f5f5f5',
          cursor: interactionMode === 'pan' ? 'grab' : 'crosshair',
        }}
        onMouseDown={handleCanvasMouseDown}
        onMouseMove={handleCanvasMouseMove}
        onMouseUp={handleCanvasMouseUp}
        onMouseLeave={handleCanvasMouseUp}
      />

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button
          onClick={() => setEyeSockets({ left: [], right: [] })}
          style={{ marginRight: '10px' }}
        >
          Clear All
        </button>
        <button
          onClick={handleUndo}
          disabled={!isUndoEnabled}
          style={{ marginRight: '10px' }}
        >
          Undo
        </button>
        <button onClick={saveEyeSockets}>Save Eye Sockets</button>
        <button onClick={drawImage}>Draw Image (in case image is stuck)</button>
      </div>
    </div>
  );
};

export default EyeSocketModal;
