import { getCurrentStepIndex } from './timeline';
import { mapPhonemeToMouthShape } from './audio';

export const drawBackgroundImage = (p5, backgroundImageData) => {
  if (!backgroundImageData) return;

  const canvasWidth = p5.width;
  const canvasHeight = p5.height;

  // Draw the image stretched to fit the entire canvas
  p5.image(backgroundImageData, 0, 0, canvasWidth, canvasHeight);
};

function findCharacter(characters, id) {
  for (let i = 0; i < characters.length; i++) {
    const char = characters[i];
    if (char.id == id) {
      return char;
    }
  }
  return false;
}

// Store dummy objects for smooth animation
const dummyCharacters = {};

// Track all active audio elements
const activeAudioElements = new Set();

// Function to pause all active audio
export const pauseAllAudio = () => {
  activeAudioElements.forEach((audio) => {
    if (audio && !audio.paused) {
      audio.pause();
    }
  });
};

export const destroyAllAudio = () => {
  activeAudioElements.clear();
};

// Function to resume all active audio
export const resumeAllAudio = () => {
  activeAudioElements.forEach((audio) => {
    if (audio && audio.paused) {
      audio.play().catch((err) => {
        console.error('Failed to resume audio:', err);
      });
    }
  });
};

export const drawEntitiesWithSound = (
  p5,
  timeline,
  currentTime,
  characters,
  objects,
  playedAudio,
  dialogs
) => {
  const currentIndex = getCurrentStepIndex(timeline, currentTime);
  if (currentIndex === -1) return; // No valid step found

  const currentStep = timeline[currentIndex];
  const nextStep = timeline[currentIndex + 1] || null; // Get next step if available

  // if (currentTime === 0) {
  //   playedAudio.clear();
  //   console.log("playAudio clear")
  // }

  const actions = currentStep.actions;
  actions.forEach((action) => {
    if (action.type === 'move') {
      const targetId = action.target;

      if (!targetId) return;

      // Find the character in the array
      let character = characters.find((c) => c.id === targetId);
      if (!character) {
        character = objects.find((c) => c.id === targetId);
      }

      const { position, rotation, duration, w, h } = action.parameters;
      let startX = position?.x ?? 0;
      let startY = position?.y ?? 0;
      let startRot = rotation ?? 0;
      let startCharW = w ?? character.bW.value;
      let startCharH = h ?? character.bH.value;
      let endCharW = startCharW;
      let endCharH = startCharH;

      let endX = startX,
        endY = startY,
        endRot = startRot;
      let progress = 1; // Default to full progress if no next step

      if (nextStep) {
        // Find next step position for interpolation
        const nextAction = nextStep.actions.find((a) => a.target === targetId);
        if (nextAction) {
          endX = nextAction.parameters.position.x;
          endY = nextAction.parameters.position.y;
          endRot = nextAction.parameters.rotation ?? startRot;
          endCharW = nextAction.parameters.w ?? character.bW.value;
          endCharH = nextAction.parameters.h ?? character.bH.value;

          // Calculate progress between the two steps
          let timeDifference = nextStep.time - currentStep.time;
          if (timeDifference > 0) {
            progress = (currentTime - currentStep.time) / timeDifference;
            progress = p5.constrain(progress, 0, 1);
          }
        }
      }

      if (character) {
        // Animate existing Regular smoothly
        character.offsetX = p5.lerp(startX, endX, progress); // X coordinate
        character.offsetY = p5.lerp(startY, endY, progress); // Y coordinate
        character.bX.value = p5.lerp(
          p5.radians(startRot),
          p5.radians(endRot),
          progress
        ); // Rotation
        // no stepW and stepH in current
        if (startCharW && startCharH && endCharW && endCharH) {
          character.stepW.value = p5.lerp(startCharW, endCharW, progress);
          character.stepH.value = p5.lerp(startCharH, endCharH, progress);
        }
      } else {
        // If dummy character doesn't exist, create one at initial position
        if (!dummyCharacters[targetId]) {
          dummyCharacters[targetId] = {
            x: startX,
            y: startY,
            rotation: startRot,
          };
        }

        // Get the dummy reference
        let dummy = dummyCharacters[targetId];

        // Interpolate dummy movement
        dummy.x = p5.lerp(startX, endX, progress);
        dummy.y = p5.lerp(startY, endY, progress);
        dummy.rotation = p5.lerp(startRot, endRot, progress);
        // dummy.width = p5.lerp(startStepW, endStepW, progress);
        // dummy.height = p5.lerp(startStepH, endStepH, progress);

        // Draw the animated dummy
        p5.push();
        p5.translate(dummy.x, dummy.y);
        p5.rotate(p5.radians(dummy.rotation));

        p5.fill(100);
        p5.noStroke();
        p5.ellipse(0, 0, 50, 100); // Body
        p5.ellipse(0, -60, 40, 40); // Head

        p5.fill(0);
        p5.textAlign(p5.CENTER);
        p5.text(targetId, 0, 50); // Label with ID

        p5.pop();
      }
    } else if (action.type === 'audio') {
      const targetId = action.target;
      const { id, duration, uuid } = action.parameters;
      // dont play the same audio again
      if (playedAudio.has(uuid)) return;

      // Find the character in the array
      let character = characters.find((c) => c.id === targetId);

      if (!character) {
        // If the character does not exist, use a dummy representation
        if (!dummyCharacters[targetId]) {
          dummyCharacters[targetId] = {
            x: 0,
            y: 0,
            rotation: 0,
            audio: null,
          };
        }

        character = dummyCharacters[targetId];
      }

      character.talking = true;

      console.log('Playing audio...', action.parameters.uuid);
      playedAudio.add(action.parameters.uuid);
      console.log('playedAudio', playedAudio);
      const audioSource = dialogs.find((dialog)=>dialog.id == id)

      console.log("audioSource", audioSource)

      character.audio = new Audio(audioSource.asset);

      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      // Create a MediaElementSource from the audio element.
      const source = audioContext.createMediaElementSource(character.audio);

      // Create an AnalyserNode for frequency data.
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048; // Adjust fftSize as needed.
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Connect the audio source to the analyser, then to the destination.
      source.connect(analyser);
      analyser.connect(audioContext.destination);

      // Add to active audio elements set
      activeAudioElements.add(character.audio);

      const storedPauseTime = sessionStorage.getItem('audioPauseTime');
      if (storedPauseTime && storedPauseTime !== '-1') {
        character.audio.currentTime = parseFloat(storedPauseTime);
        // Optionally, clear the stored pause time after using it.
        sessionStorage.setItem('audioPauseTime', -1);
      }

      // const audio = new Audio();
      // audio.crossOrigin = "anonymous";
      // audio.src = "https://replicantlife.com/audio?tid=79241";
      // audio.play()
      character.audio.crossOrigin = 'anonymous';
      character.audio.addEventListener('ended', () => {
        console.log('Audio playback has ended.', action.parameters.uuid);
      });
      character.audio
        .play()
        .then(() => {
          (function analyze() {
            analyser.getByteFrequencyData(dataArray);
            const soundFrequency = mapPhonemeToMouthShape(dataArray);
            // console.log("soundFrequency", soundFrequency)
            character.soundFrequency = soundFrequency;
            //console.log('Selected mouth shape:', mouthShape);
            //console.log('Frequency Data:', dataArray);
            // You can add further processing here to approximate phoneme detection.
            requestAnimationFrame(analyze);
          })();

          character.audio.onended = () => {
            console.log('Audio ended', action.parameters.uuid);
            // Remove from active audio elements set before nullifying
            activeAudioElements.delete(character.audio);
            character.talking = true;
            character.audio = null;
          };
        })
        .catch((err) => {
          console.error('Audio playback failed:', err);
          // Remove from active audio elements set on error
          activeAudioElements.delete(character.audio);
        });
      

      // Optional: Visual Indicator for Audio
      p5.push();
      p5.fill(255, 0, 0);
      p5.noStroke();
      p5.ellipse(character.x, character.y - 80, 20, 20); // Small red circle above character
      p5.pop();
    }
  });

  // // Draw all real characters
  // const elapsedTime = currentTime;
  // characters.forEach(character => {
  //     character.drawCharacter(p5, mouthImages, elapsedTime);
  // });
};


export const drawEntitiesOnly = (p5, timeline, currentTime, characters, objects) => {
  const currentIndex = getCurrentStepIndex(timeline, currentTime);
  if (currentIndex === -1) return; // No valid step found

  const currentStep = timeline[currentIndex];
  const nextStep = timeline[currentIndex + 1] || null; // Get next step if available

  const actions = currentStep.actions;
  actions.forEach((action) => {
    if (action.type === 'move') {
      const targetId = action.target;
      if (!targetId) return;

      // Find the character in the array or from objects
      let character = characters.find((c) => c.id === targetId);
      if (!character) {
        character = objects.find((c) => c.id === targetId);
      }

      const { position, rotation, w, h } = action.parameters;
      const startX = position?.x ?? 0;
      const startY = position?.y ?? 0;
      const startRot = rotation ?? 0;
      // Use character's default dimensions if available
      const startCharW = w ?? (character ? character.bW.value : 0);
      const startCharH = h ?? (character ? character.bH.value : 0);
      let endCharW = startCharW;
      let endCharH = startCharH;

      let endX = startX,
        endY = startY,
        endRot = startRot;
      let progress = 1; // Default to full progress if no next step

      if (nextStep) {
        // Find the next step's action for interpolation
        const nextAction = nextStep.actions.find((a) => a.target === targetId);
        if (nextAction) {
          endX = nextAction.parameters.position.x;
          endY = nextAction.parameters.position.y;
          endRot = nextAction.parameters.rotation ?? startRot;
          endCharW =
            nextAction.parameters.w ?? (character ? character.bW.value : startCharW);
          endCharH =
            nextAction.parameters.h ?? (character ? character.bH.value : startCharH);

          const timeDifference = nextStep.time - currentStep.time;
          if (timeDifference > 0) {
            progress = (currentTime - currentStep.time) / timeDifference;
            progress = p5.constrain(progress, 0, 1);
          }
        }
      }

      if (character) {
        // Animate an existing character smoothly
        character.offsetX = p5.lerp(startX, endX, progress);
        character.offsetY = p5.lerp(startY, endY, progress);
        character.bX.value = p5.lerp(
          p5.radians(startRot),
          p5.radians(endRot),
          progress
        );
        if (startCharW && startCharH && endCharW && endCharH) {
          character.stepW.value = p5.lerp(startCharW, endCharW, progress);
          character.stepH.value = p5.lerp(startCharH, endCharH, progress);
        }
      } else {
        // If the character doesn't exist, use a dummy character for animation
        if (!dummyCharacters[targetId]) {
          dummyCharacters[targetId] = {
            x: startX,
            y: startY,
            rotation: startRot,
          };
        }
        let dummy = dummyCharacters[targetId];

        dummy.x = p5.lerp(startX, endX, progress);
        dummy.y = p5.lerp(startY, endY, progress);
        dummy.rotation = p5.lerp(startRot, endRot, progress);

        // Draw the dummy character
        p5.push();
        p5.translate(dummy.x, dummy.y);
        p5.rotate(p5.radians(dummy.rotation));
        p5.fill(100);
        p5.noStroke();
        p5.ellipse(0, 0, 50, 100); // Body
        p5.ellipse(0, -60, 40, 40); // Head
        p5.fill(0);
        p5.textAlign(p5.CENTER);
        p5.text(targetId, 0, 50); // Label with ID
        p5.pop();
      }
    }
  });
};

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function drawP5Shape({ p5, fillColour, shape, arg1, arg2, arg3, arg4 }) {
  p5.fill(fillColour);
  p5.stroke('black');
  if (shape === 'rect') {
    p5.rect(arg1, arg2, arg3, arg4);
  } else if (shape === 'ellipse') {
    p5.ellipse(arg1, arg2, arg3, arg4);
  }
}
