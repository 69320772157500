import '../../styles/controls.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { GlobalContext } from '../../App';
import { sumTimelineDurations } from '../../utils/timeline';
import {
  degreesToRadians,
  pauseAllAudio,
  destroyAllAudio,
} from '../../utils/p5Instance';
import { RegularCharacter } from '../../constants/regular';
import { SceneObject } from '../../constants/object';
import { P5CustomObject } from '../../constants/p5object';
import { convertBlobUrlToBase64, cleanObjectForExport, cleanBase64 } from '../../utils/file';
import useModal from '../../hooks/useModal';
import { importTimelineUtils, normalizeTimelineTimes, createCachedTimelineUtils } from '../../utils/timeline';


const ActionControl = () => {
  const {
    isPlaying,
    setIsPlaying,
    timeline,
    setTimeline,
    currentTime,
    setCurrentTime,
    totalTime,
    characters,
    backgroundImage,
    setBackgroundImage,
    setCharacters,
    setIsRecording,
    objects,
    dialogs,
    setObjects,
    playedAudioSet,
    setBackgroundImageUrl,
    setPlayedAudioSet,
    setDialogs,
    cachedTimeline,
    setCachedTimeline,
    notify,
    isCachedTimelineUpdated,
    setIsCachedTimelineUpdated
  } = useContext(GlobalContext);

  const [stateTime, setStateTime] = useState(0);
  const fileRef = useRef();
  const charRef = useRef();

  useEffect(() => {
    if (isCachedTimelineUpdated) {
      totalTime.current = sumTimelineDurations(cachedTimeline);
      setStateTime(sumTimelineDurations(cachedTimeline) + 1);
    } else {
      totalTime.current = sumTimelineDurations(cachedTimeline);
      setStateTime(sumTimelineDurations(cachedTimeline) + 1);
    }
  }, [isCachedTimelineUpdated]);

  const togglePlay = () => {
    reset();
    if (!isPlaying) {
      setPlayedAudioSet(new Set());
      startRecording();
    }
  };

  const togglePlayWithoutRecord = () => {
    console.log("playedAudioSet", playedAudioSet)
    if (!isPlaying) {
      setPlayedAudioSet(new Set());
      sessionStorage.setItem('audioPauseTime', -1);
    }
    if (isPlaying) {
      sessionStorage.setItem('audioPauseTime', currentTime);
      // Ensure all audio is paused when timeline is paused
      pauseAllAudio();
    }
    setIsPlaying((isPlaying) => !isPlaying);
  };

  const reset = () => {
    setIsPlaying(false);
    setCurrentTime(0);
    setPlayedAudioSet(new Set());
    sessionStorage.setItem('audioPauseTime', -1);
    // Ensure all audio is stopped when resetting
    destroyAllAudio();

    // Apply the first step's state if timeline exists
    if (timeline && timeline.length > 0) {
      const firstStep = timeline[0];
      firstStep.actions.forEach((action) => {
        if (action.type === 'move') {
          const targetChar = characters.find((c) => c.id === action.target);
          if (targetChar) {
            // Set initial position and size from first step
            targetChar.offsetX = action.parameters.position.x;
            targetChar.offsetY = action.parameters.position.y;
            targetChar.bX.value = action.parameters.rotation
              ? degreesToRadians(action.parameters.rotation)
              : 0;
            targetChar.bW.value =
              action.parameters.position.w || targetChar.bW.value;
            targetChar.bH.value =
              action.parameters.position.h || targetChar.bH.value;
          }
        }
      });
    }
  };

  // Function to get the current step index
  const getCurrentStepIndex = () => {
    if (!timeline || timeline.length === 0) return 0;

    let index = 0;
    for (let i = 0; i < timeline.length; i++) {
      if (timeline[i].time <= currentTime) {
        index = i;
      } else {
        break;
      }
    }
    return index;
  };

  const applyStepState = (step) => {
    if (!step) return;

    step.actions.forEach((action) => {
      if (action.type === 'move') {
        const targetChar = characters.find((c) => c.id === action.target);
        if (targetChar) {
          // Apply position, rotation, and size from the step
          targetChar.offsetX = action.parameters.position.x;
          targetChar.offsetY = action.parameters.position.y;
          targetChar.bX.value = action.parameters.rotation
            ? degreesToRadians(action.parameters.rotation)
            : 0;
          targetChar.bW.value =
            action.parameters.position.w || targetChar.bW.value;
          targetChar.bH.value =
            action.parameters.position.h || targetChar.bH.value;
        }
      }
    });
  };

  const prev = () => {
    setIsPlaying(false);
    if (!timeline || timeline.length === 0) return;

    const currentIndex = getCurrentStepIndex();
    if (currentIndex > 0) {
      const prevStep = timeline[currentIndex - 1];
      setCurrentTime(prevStep.time);
      applyStepState(prevStep);
    }
  };

  const next = () => {
    setIsPlaying(false);
    if (!timeline || timeline.length === 0) return;

    const currentIndex = getCurrentStepIndex();
    if (currentIndex < timeline.length - 1) {
      const nextStep = timeline[currentIndex + 1];
      setCurrentTime(nextStep.time);
      applyStepState(nextStep);
    }
  };

  const end = () => {
    setIsPlaying(false);
    if (!timeline || timeline.length === 0) return;

    const lastStep = timeline[timeline.length - 1];
    setCurrentTime(lastStep.time);
    applyStepState(lastStep);
  };

  const startRecording = async () => {
    const targetElement = document.getElementById('defaultCanvas0'); // p5.js canvas

    try {
      // 🎥 Capture video from p5.js canvas
      const croppedStream = targetElement.captureStream(60);

      setIsRecording(true);

      // 🔊 Capture audio (must request video too for compatibility)
      const audioStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: { suppressLocalAudioPlayback: false },
        preferCurrentTab: true,
      });

      // Extract audio tracks
      const audioTracks = audioStream.getAudioTracks();
      if (audioTracks.length === 0) {
        console.warn(
          '⚠️ No audio detected! Ensure the tab has active audio playing.'
        );
      }

      // 🎵 Web Audio API setup
      const audioContext = new AudioContext();
      const audioSource = audioContext.createMediaStreamSource(audioStream);
      const audioDestination = audioContext.createMediaStreamDestination();
      audioSource.connect(audioDestination);

      // 🎬 Combine streams
      const combinedStream = new MediaStream();
      croppedStream
        .getVideoTracks()
        .forEach((track) => combinedStream.addTrack(track));
      audioDestination.stream
        .getAudioTracks()
        .forEach((track) => combinedStream.addTrack(track));

      // 🏆 Start recording
      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: 'video/webm;codecs=vp8,opus',
        videoBitsPerSecond: 5000000, // 5 Mbps for video
        audioBitsPerSecond: 128000, // 128 kbps for audio
      });
      let chunks = [];

      mediaRecorder.ondataavailable = (event) => chunks.push(event.data);

      // Only create and trigger download when recording actually completes
      mediaRecorder.onstop = () => {
        if (chunks.length > 0) {
          const blob = new Blob(chunks, { type: 'video/webm' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'cartoon_animation.webm';
          a.click();
          URL.revokeObjectURL(url);
        }
      };

      // Start recording and playing
      mediaRecorder.start();
      setIsPlaying(true);
      console.log('🎥 Recording started...');

      // Stop recording when animation completes
      const recordingDuration = (stateTime + 0.5) * 1000; // Add 0.5s buffer
      setTimeout(() => {
        if (mediaRecorder.state === 'recording') {
          setIsPlaying(false);
          mediaRecorder.stop();
          audioStream.getTracks().forEach((track) => track.stop());
          console.log('⏹️ Recording completed');
          setIsRecording(false);
          console.log('⏹️ Recording stopped by timeout.');
        }
      }, recordingDuration);

      // Handle premature stream end
      combinedStream.getVideoTracks()[0].onended = () => {
        if (mediaRecorder.state === 'recording') {
          setIsPlaying(false);
          mediaRecorder.stop();
          audioStream.getTracks().forEach((track) => track.stop());
          console.log('⏹️ Recording stopped because stream ended');
        }
      };
    } catch (error) {
      console.error('❌ Error capturing media:', error);
      setIsPlaying(false);
    }
  };

  const clearTimeline = () => {
    setTimeline([]);
    // Stop all audio when clearing the timeline
    pauseAllAudio();
    setPlayedAudioSet(new Set());
  };

  const importTimeline = () => {
    const file = fileRef.current.files[0];
    if (!file) {
      console.error('❌ No file selected.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const json = JSON.parse(event.target.result);
        const output = importTimelineUtils(json);
        console.log('output', output);
        setDialogs(output.dialogs)
        setBackgroundImage(output.background);
        setTimeline(output.timeline);
        setCharacters(output.characters);
        setObjects(output.objects);
      } catch (error) {
        console.error('❌ Error parsing JSON:', error);
      }
    };

    reader.readAsText(file);
  };


  const exportTimeline = async () => {
    const exportData = {
      timeline: timeline,
      dialogs: dialogs,
      characters: characters.map((char) => cleanObjectForExport(char)),
      objects: objects.map((obj) => cleanObjectForExport(obj)),
      scene: {
        background: backgroundImage
      },
    };

    // console.log('exportData', exportData);

    // if (exportOption === 'fileReference') {
    //   exportData.characters = characters.map((char) => cleanBase64(char));
    // }

    console.log('exportData', exportData);

    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'timeline.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const importChar = () => {
    const file = charRef.current.files[0];
    if (!file) {
      console.error('❌ No file selected.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const charData = JSON.parse(event.target.result);

        // Create a new RegularCharacter instance with basic properties
        const restoredChar = new RegularCharacter(
          charData.offsetX || 0,
          charData.offsetY || 0,
          charData.id,
          charData.name,
          charData.image
        );

        // Restore additional properties from the imported data
        restoredChar.zIndex = charData.zIndex || 0;
        restoredChar.eyeSockets = charData.eyeSockets || {
          left: [],
          right: [],
        };
        restoredChar.eyeLidImage = charData.eyeLidImage || '';
        restoredChar.eyeBallImage = charData.eyeBallImage || '';
        restoredChar.leftImage = charData.leftImage || '';
        restoredChar.rightImage = charData.rightImage || '';
        restoredChar.backImage = charData.backImage || '';
        restoredChar.talking = true;
        restoredChar.showEyes = true;
        restoredChar.soundFrequency = 0;

        // Restore sliders with their values if they exist in the imported data
        if (charData.sliders && Array.isArray(charData.sliders)) {
          charData.sliders.forEach((importedSlider) => {
            const matchingSlider = restoredChar.sliders.find(
              (s) => s.name === importedSlider.name
            );
            if (matchingSlider) {
              matchingSlider.value = importedSlider.value;
              matchingSlider.min = importedSlider.min;
              matchingSlider.max = importedSlider.max;
              matchingSlider.step = importedSlider.step;
            }
          });
        }

        // Check if a character with this ID already exists
        const existingCharIndex = characters.findIndex(
          (char) => char.id === restoredChar.id
        );
        if (existingCharIndex !== -1) {
          restoredChar.id = `${restoredChar.id}_imported_${Date.now()}`;
        }

        // Add the new character to the global context
        setCharacters((prevCharacters) => [...prevCharacters, restoredChar]);

        // Clear the file input
        charRef.current.value = '';

        console.log(
          '✅ Character imported successfully:',
          restoredChar.name || restoredChar.id
        );
      } catch (error) {
        console.error('❌ Error importing character:', error);
      }
    };

    reader.readAsText(file);
  };

  const createCachedTimeline = async () => {
    const newTimeline = await createCachedTimelineUtils(timeline, dialogs)
    setIsCachedTimelineUpdated(true)
    notify("set cached timeline complete")
    console.log("newTimeline", newTimeline)
    setCachedTimeline(normalizeTimelineTimes(newTimeline))
  };

  return (
    <>
      <div className="flex flex-row">
        <button className="btn" id="generate">
          Generate Timeline (under development)
        </button>
        <button className="btn" id="export" onClick={exportTimeline}>
          Export Timeline{' '}
        </button>
        <div style={{ border: "1px black solid", alignItems: "center" }} className="flex flex-row">
          <input type="file" id="import-file" ref={fileRef} accept=".json" />
          <button className="btn" id="import" onClick={importTimeline}>
            Import Timeline{' '}
          </button>
        </div>
        <div style={{ border: "1px black solid", alignItems: "center" }} className="flex flex-row">
          <input type="file" id="import-char" ref={charRef} accept=".json" />
          <button className="btn" id="import_char" onClick={importChar}>
            Import Character{' '}
          </button>
        </div>
      </div>
      <div className="flex-container">
        <button className="btn" id="play" onClick={togglePlay}>
          {isPlaying ? 'Pause' : 'Play with record'}
        </button>
        <button className="btn" onClick={togglePlayWithoutRecord}>
          {isPlaying ? 'Pause' : 'Play Without Record'}
        </button>
        <button className="btn" id="reset" onClick={reset}>
          Reset
        </button>
        <button className="btn" id="prev_step" onClick={prev}>
          Prev
        </button>
        <button className="btn" id="next_step" onClick={next}>
          Next
        </button>
        <button className="btn" id="end_step" onClick={end}>
          End
        </button>
        <button className="btn" id="clearTimeline" onClick={clearTimeline}>
          Clear Timeline
        </button>
        <button className="btn" onClick={createCachedTimeline}>
          Create Cached
        </button>
        {isCachedTimelineUpdated ? "Cached Timeline up to date" : "Timeline not cached"}
      </div>
      Time:{' '}
      <span id="elapsed-time">
        {currentTime === 0 ? '0.00s' : `${currentTime.toFixed(2)}s`}
      </span>
      Step:{' '}
      <span id="current-step">
        {timeline.length === 0 ? 0 : getCurrentStepIndex() + 1} / {timeline.length}
      </span>
      Total Time: {stateTime}
    </>
  );
};

export default ActionControl;
