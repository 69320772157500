import { SceneObject } from './object';
import { loadAsset } from '../utils/assetLoader';
import { generateRandom } from '../utils/math';
import { isLastStep } from '../utils/timeline';

export class RegularCharacter extends SceneObject {
  constructor(offsetX, offsetY, id, name, image, bodyImgUrl) {
    super(offsetX, offsetY, id, name, image, bodyImgUrl);

    // Additional properties specific to RegularCharacter
    this.mSize = {
      min: 0,
      max: 100,
      value: 0,
      step: 1,
      x: 0,
      y: 0,
      label: 'Mouth Size Precentage of body',
      name: 'mSize',
    };
    this.mOffsetX = {
      min: 0,
      max: 100,
      value: 0,
      step: 1,
      x: 0,
      y: 0,
      label: 'Mouth X Precentage of body',
      name: 'mOffsetX',
    };
    this.mOffsetY = {
      min: 0,
      max: 100,
      value: 0,
      step: 1,
      x: 0,
      y: 0,
      label: 'Mouth Y Precentage of body',
      name: 'mOffsetY',
    };

    this.bY = {
      min: -Math.PI / 2,
      max: Math.PI / 2,
      value: 0,
      step: 0.01,
      x: 0,
      y: 0,
      label: 'Base Y',
      name: 'bY',
    };

    // Array of all slider-like controls for your panel (feel free to order them as desired)
    this.sliders = [
      this.mSize,
      this.mOffsetX,
      this.mOffsetY,
      this.bW,
      this.bH,
      this.bX,
      this.bY,
    ];

    this.talking = true;
    this.soundFrequency = 0; // int
    this.showEyes = true;

    this.eyePositions = {
      left: { x: 0, y: 0 },
      right: { x: 0, y: 0 },
    };
    this.eyeUpdateInterval = 2;
    this.eyeBlinkInterval = null;
    this.eyeLastUpdatedTime = 0;
    this.eyeLidLastUpdatedTime = 0;
    this.showEyeLidLimit = 20;
    this.currentEyeLidCount = 0;
    this.eyeLidImage = '';
    this.eyeLidImageP5 = '';
    this.eyeBallImage = '';
    this.eyeBallImageP5 = '';
    this.eyeSocketExLid = {
      left: [],
      right: [],
    };
  }

  // Override the parent's drawObject method
  drawObject(
    p,
    mouthImages,
    elapsedTime,
    currentAction,
    isPlaying,
    currentTime,
    eyeBallImage,
    eyeLidImage
  ) {
    this.drawCharacter(
      p,
      mouthImages,
      elapsedTime,
      currentAction,
      isPlaying,
      currentTime,
      eyeBallImage,
      eyeLidImage
    );
  }

  // Existing RegularCharacter methods
  drawCharacter(
    p,
    mouthImages,
    elapsedTime,
    currentAction,
    isPlaying,
    currentTime,
    eyeBallImage,
    eyeLidImage,
    timeline
  ) {
    p.push();
    p.translate(this.offsetX, this.offsetY);
    p.rotate(this.bX.value);

    // convert base64 into p5 image object and assign it to this.bodyImg
    if (!this.bodyImg && this.image) {
      // p.loadImage(this.image, (img) => {
      //   this.bodyImg = img;
      // });
      loadAsset(this.image, p, (img) => {
        this.bodyImg = img;
      });
    } else if (!this.bodyImg && this.bodyImgUrl) {
      loadAsset(this.bodyImgUrl, p, (img) => {
        this.bodyImg = img;
      });
    } else if (!this.leftImageP5 && this.leftImage) {
      loadAsset(this.leftImage, p, (img) => {
        this.leftImageP5 = img;
      });
    } else if (!this.leftImageP5 && this.leftImageUrl) {
      loadAsset(this.leftImageUrl, p, (img) => {
        this.leftImageP5 = img;
      });
    } else if (!this.rightImageP5 && this.rightImage) {
      // p.loadImage(this.rightImage, (img) => {
      //   this.rightImageP5 = img;
      // });
      loadAsset(this.rightImage, p, (img) => {
        this.rightImageP5 = img;
      });
    } else if (!this.rightImageP5 && this.rightImageUrl) {
      loadAsset(this.rightImageUrl, p, (img) => {
        this.leftImageP5 = img;
      });
    } else if (!this.backImageP5 && this.backImage) {
      // p.loadImage(this.backImage, (img) => {
      //   this.backImageP5 = img;
      // });
      loadAsset(this.backImage, p, (img) => {
        this.backImageP5 = img;
      });
    } else if (!this.backImageP5 && this.backImageUrl) {
      loadAsset(this.backImageUrl, p, (img) => {
        this.backImageP5 = img;
      });
    } else {
      const bodyView = currentAction?.view;
      let renderedBodyImg = ""
      if (this.bodyImg) {
        renderedBodyImg = this.bodyImg
      } else {
        renderedBodyImg = this.bodyImgUrl;
      }
      if (bodyView === 'left') {
        renderedBodyImg = this.leftImageP5;
      } else if (bodyView === 'right') {
        renderedBodyImg = this.rightImageP5;
      } else if (bodyView === 'back') {
        renderedBodyImg = this.backImageP5;
      }
      p.imageMode(p.CENTER);
      if (!renderedBodyImg) {
        if (this.bodyImg) {
          renderedBodyImg = this.bodyImg
        } else {
          renderedBodyImg = this.bodyImgUrl;
        }
      }
      if (isPlaying) {
        p.image(renderedBodyImg, 0, 0, this.stepW.value, this.stepH.value);
      } else {
        if (
          currentAction?.parameters?.w != null &&
          currentAction?.parameters?.h != null
        ) {
          p.image(renderedBodyImg, 0, 0, this.stepW.value, this.stepH.value);
        } else {
          p.image(renderedBodyImg, 0, 0, this.bW.value, this.bH.value);
        }
      }
      p.imageMode(p.CORNER);
    }

    // do the same for eyeLid
    if (this.eyeLidImage) {
      if (!this.eyeLidImageP5) {
        // p.loadImage(this.eyeLidImage, (img) => {
        //   this.eyeLidImageP5 = img;
        // });
        loadAsset(this.eyeLidImage, p, (img) => {
          this.eyeLidImageP5 = img;
        });
      }
    }

    if (this.eyeBallImage) {
      if (!this.eyeBallImageP5) {
        // p.loadImage(this.eyeBallImage, (img) => {
        //   this.eyeBallImageP5 = img;
        // });
        loadAsset(this.eyeBallImage, p, (img) => {
          this.eyeBallImageP5 = img;
        });
      }
    }

    // Draw eyes
    if (this.showEyes) {
      this.drawEyes(p, eyeBallImage, currentTime, currentAction, eyeLidImage, timeline);
    }

    this.drawMouth(p, mouthImages, currentAction);

    p.pop();
  }

  drawMouth(p, mouthImages, currentAction) {
    const thresholds = [
      { max: 10, mouth: 'closed' },
      { max: 20, mouth: 'slightly-open' },
      { max: 30, mouth: 'half-open' },
      { max: 40, mouth: 'ds' },
      { max: 50, mouth: 'Ee' },
      { max: 60, mouth: 'open' },
      { max: Infinity, mouth: 'wide-open' },
      { max: Infinity, mouth: 'wide-open' },
    ];

    let mouthImageIndex = 0;
    for (let i = 0; i < thresholds.length; i++) {
      if (this.soundFrequency <= thresholds[i].max) {
        mouthImageIndex = i;
        break;
      }
    }

    let currentWidth, currentHeight;
    if (
      currentAction?.parameters?.w != null &&
      currentAction?.parameters?.h != null
    ) {
      currentWidth = this.stepW.value;
      currentHeight = this.stepH.value;
    } else {
      currentWidth = this.bW.value;
      currentHeight = this.bH.value;
    }

    if (this.currentMSizeLerp === undefined) {
      this.currentMSizeLerp = this.mSize.value;
      this.currentMOffsetXLerp = this.mOffsetX.value;
      this.currentMOffsetYLerp = this.mOffsetY.value;
    }

    const lerpFactor = 0.1;
    this.currentMSizeLerp = p.lerp(
      this.currentMSizeLerp,
      this.mSize.value,
      lerpFactor
    );
    this.currentMOffsetXLerp = p.lerp(
      this.currentMOffsetXLerp,
      this.mOffsetX.value,
      lerpFactor
    );
    this.currentMOffsetYLerp = p.lerp(
      this.currentMOffsetYLerp,
      this.mOffsetY.value,
      lerpFactor
    );

    const mouthW = currentWidth * (this.currentMSizeLerp / 100);
    const mouthH = currentHeight * (this.currentMSizeLerp / 100);

    const centerPercent = 50;
    const mouthX =
      ((this.currentMOffsetXLerp - centerPercent) / 100) * currentWidth;
    const mouthY =
      ((this.currentMOffsetYLerp - centerPercent) / 100) * currentHeight;

    p.push();
    p.imageMode(p.CENTER);
    p.image(mouthImages[mouthImageIndex], mouthX, mouthY, mouthW, mouthH);
    p.pop();
  }

  drawEyes(p, eyeBallImage, currentTime, currentAction, eyeLidImage, timeline) {
    if (currentTime < 1) {
      this.eyeLastUpdatedTime = 0;
      this.eyeLidLastUpdatedTime = 0;
    }

    if (!this.eyeBlinkInterval) this.eyeBlinkInterval = generateRandom();

    if (!this.showEyes || !this.eyeSockets || !eyeBallImage) return;

    // Check if eye movement should be disabled for this action
    const shouldUpdateEyes = !(
      currentAction &&
      currentAction.hasOwnProperty('moveEye') &&
      currentAction.moveEye === false
    );

    // Find speaking character in current step's actions
    let speakingCharacterPos = null;
    if (currentAction && Array.isArray(currentAction.stepActions)) {
      const audioAction = currentAction.stepActions.find(
        (action) => action.type === 'audio'
      );

      if (audioAction) {
        // Find the move action for the speaking character to get position
        const speakerMoveAction = currentAction.stepActions.find(
          (action) =>
            action.type === 'move' && action.target === audioAction.target
        );

        if (speakerMoveAction) {
          speakingCharacterPos = speakerMoveAction.parameters.position;
        }
      }
    }

    // Update eye positions based on speaking character or default behavior
    if (currentAction?.eyeDirection) {
      // Explicit eye direction takes precedence
      this.updateEyePositions(currentAction.eyeDirection);
    } else if (
      speakingCharacterPos &&
      shouldUpdateEyes &&
      currentTime - this.eyeLastUpdatedTime >= this.eyeUpdateInterval
    ) {
      this.eyeLastUpdatedTime = currentTime;
      // Calculate direction to speaking character
      const directionToSpeaker =
        this.getDirectionToPosition(speakingCharacterPos);

      // 50% chance to look at speaker, 50% chance for random movement
      const shouldLookAtSpeaker = Math.random() < 0.5;

      this.updateEyePositions(shouldLookAtSpeaker ? directionToSpeaker : '');
    } else if (
      shouldUpdateEyes &&
      currentTime - this.eyeLastUpdatedTime >= this.eyeUpdateInterval
    ) {
      this.eyeLastUpdatedTime = currentTime;
      this.updateEyePositions();
    }

    // Retrieve the body dimensions.
    const bodyWidth = this.bW.value;
    const bodyHeight = this.bH.value;

    // Process each eye socket ex eyelids for iris (left and right)
    ['left', 'right'].forEach((side) => {
      if (!this.eyeSocketExLid[side] || this.eyeSocketExLid[side].length < 3)
        return;

      // Determine the bounding box of the eye socket in percentage (0 to 100)
      let minXPercent = Infinity,
        minYPercent = Infinity,
        maxXPercent = -Infinity,
        maxYPercent = -Infinity;

      this.eyeSocketExLid[side].forEach((point) => {
        minXPercent = Math.min(minXPercent, point.x);
        minYPercent = Math.min(minYPercent, point.y);
        maxXPercent = Math.max(maxXPercent, point.x);
        maxYPercent = Math.max(maxYPercent, point.y);
      });

      // Convert percentages to pixel coordinates.
      // Since the body is centered at (0, 0), the left edge is at -bodyWidth/2 and the right edge at +bodyWidth/2.
      const minXPixel = (minXPercent / 100 - 0.5) * bodyWidth;
      const maxXPixel = (maxXPercent / 100 - 0.5) * bodyWidth;
      const minYPixel = (minYPercent / 100 - 0.5) * bodyHeight;
      const maxYPixel = (maxYPercent / 100 - 0.5) * bodyHeight;

      // Compute the center of the bounding box.
      const centerX = (minXPixel + maxXPixel) / 2;
      const centerY = (minYPixel + maxYPixel) / 2;

      // Compute the eye's drawing width as a fraction of the socket's bounding box.
      const socketWidth = maxXPixel - minXPixel;
      const socketHeight = maxYPixel - minYPixel;
      const calculatedEyeWidth = socketWidth * 0.7;

      p.push();
      p.imageMode(p.CENTER);

      // Use the randomized eye offset (stored in eyePositions as percentages in [0, 1]; 0.5 is centered)
      // and convert that into an offset in pixels relative to the bounding box.
      const offsetX = (this.eyePositions[side].x - 0.5) * socketWidth;
      const offsetY = (this.eyePositions[side].y - 0.5) * socketHeight;

      // Determine final eye position.
      const eyeX = centerX + offsetX / 2;
      const eyeY = centerY + offsetY / 2;

      // Get the original dimensions of the eye image
      const originalEyeWidth = eyeBallImage.width;
      const originalEyeHeight = eyeBallImage.height;
      const eyeAspectRatio = originalEyeWidth / originalEyeHeight;

      // Compute the eye's height to preserve the original aspect ratio
      const calculatedEyeHeight = calculatedEyeWidth / eyeAspectRatio;

      p.image(
        // eye balls (iris)
        eyeBallImage,
        eyeX,
        eyeY,
        calculatedEyeWidth,
        calculatedEyeHeight
      );

      p.pop();
    });

    // Process each eye socket for eyelid (left and right)
    ['left', 'right'].forEach((side) => {
      if (!this.eyeSockets[side] || this.eyeSockets[side].length < 3) return;

      // Determine the bounding box of the eye socket in percentage (0 to 100)
      let minXPercent = Infinity,
        minYPercent = Infinity,
        maxXPercent = -Infinity,
        maxYPercent = -Infinity;

      this.eyeSockets[side].forEach((point) => {
        minXPercent = Math.min(minXPercent, point.x);
        minYPercent = Math.min(minYPercent, point.y);
        maxXPercent = Math.max(maxXPercent, point.x);
        maxYPercent = Math.max(maxYPercent, point.y);
      });

      // Convert percentages to pixel coordinates.
      // Since the body is centered at (0, 0), the left edge is at -bodyWidth/2 and the right edge at +bodyWidth/2.
      const minXPixel = (minXPercent / 100 - 0.5) * bodyWidth;
      const maxXPixel = (maxXPercent / 100 - 0.5) * bodyWidth;
      const minYPixel = (minYPercent / 100 - 0.5) * bodyHeight;
      const maxYPixel = (maxYPercent / 100 - 0.5) * bodyHeight;

      // Compute the center of the bounding box.
      const centerX = (minXPixel + maxXPixel) / 2;
      const centerY = (minYPixel + maxYPixel) / 2;

      // Compute the eye's drawing width as a fraction of the socket's bounding box.
      const socketWidth = maxXPixel - minXPixel;
      const socketHeight = maxYPixel - minYPixel;

      p.push();
      p.imageMode(p.CENTER);

      if (currentTime - this.eyeLidLastUpdatedTime >= this.eyeBlinkInterval) {
        this.currentEyeLidCount += 1;
        if (this.currentEyeLidCount > this.showEyeLidLimit) {
          this.currentEyeLidCount = 0;
          this.eyeBlinkInterval = generateRandom();
          if (this.eyeBlinkInterval < 3) this.eyeBlinkInterval = 2;
          this.eyeLidLastUpdatedTime = currentTime;
        }
        const shouldEyeStayClose = isLastStep(timeline, currentTime) // we dont want the char to blink at last step
        if (eyeLidImage && !shouldEyeStayClose) {
          // Use the eyelid image for blinking
          p.image(
            eyeLidImage,
            centerX,
            centerY,
            socketWidth + 8,
            socketHeight + 8
          );
        }
      }

      p.pop();
    });
  }

  updateEyePositions(direction = '') {
    // Possible directions for the eyes
    const directions = [
      'top',
      'bottom',
      'left',
      'right',
      'left-top',
      'left-bottom',
      'right-top',
      'right-bottom',
      'centre',
    ];

    // Helper: randomly pick one direction
    const randomDirection = () => {
      return directions[Math.floor(Math.random() * directions.length)];
    };

    // Map each direction to a fractional position within the socket
    const getEyeFraction = (dir) => {
      switch (dir) {
        case 'top':
          return { x: 0.5, y: 0 };
        case 'bottom':
          return { x: 0.5, y: 1 };
        case 'left':
          return { x: 0, y: 0.5 };
        case 'right':
          return { x: 1, y: 0.5 };
        case 'left-top':
          return { x: 0, y: 0 };
        case 'left-bottom':
          return { x: 0, y: 1 };
        case 'right-top':
          return { x: 1, y: 0 };
        case 'right-bottom':
          return { x: 1, y: 1 };
        case 'centre':
          return { x: 0.5, y: 0.5 };
        default:
          return { x: 0.5, y: 0.5 };
      }
    };

    // Use the provided direction if available; otherwise, pick a random one
    const chosenDirection = direction === '' ? randomDirection() : direction;

    // Store the chosen direction for next time
    this.lastDirection = chosenDirection;

    // Update both eyes with the chosen direction
    this.eyePositions.left = getEyeFraction(chosenDirection);
    this.eyePositions.right = getEyeFraction(chosenDirection);
  }

  getDirectionToPosition(targetPos) {
    // Calculate relative position from character center to target
    const dx = targetPos.x - this.offsetX;
    const dy = targetPos.y - this.offsetY;

    // Calculate distance from character to target
    const distance = Math.sqrt(dx * dx + dy * dy);

    // If target is very close to character (within 50 pixels), return 'centre'
    if (distance < 50) {
      return 'centre';
    }

    // Determine rough direction based on angle
    const angle = Math.atan2(dy, dx);
    const degrees = angle * (180 / Math.PI);

    // Map angle to one of our predefined directions
    if (degrees > -22.5 && degrees <= 22.5) return 'right';
    if (degrees > 22.5 && degrees <= 67.5) return 'right-bottom';
    if (degrees > 67.5 && degrees <= 112.5) return 'bottom';
    if (degrees > 112.5 && degrees <= 157.5) return 'left-bottom';
    if (degrees > 157.5 || degrees <= -157.5) return 'left';
    if (degrees > -157.5 && degrees <= -112.5) return 'left-top';
    if (degrees > -112.5 && degrees <= -67.5) return 'top';
    if (degrees > -67.5 && degrees <= -22.5) return 'right-top';

    return ''; // fallback to random if something goes wrong
  }
}
