import { loadAsset } from '../utils/assetLoader';

export class SceneObject {
  constructor(offsetX, offsetY, id, name, image, bodyImgUrl) {
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.id = id;
    this.name = name;
    this.image = image; // front image base64
    this.bodyImg = null; // front image p5 js
    this.bodyImgUrl = bodyImgUrl; // front image file reference js
    this.backImage = null; // back image base64
    this.backImageP5 = null; // back image p5 js
    this.backImageUrl = null; // back image file reference js
    this.rightImage = null; // rightImage image base64
    this.rightImageP5 = null; // rightImage image p5 js
    this.rightImageUrl = null; // right image file reference js
    this.leftImage = null; // left image base64
    this.leftImageP5 = null; // left image p5 js
    this.leftImageUrl = null; // left image file reference js
    this.objectType = 'generic';

    // Basic transformations
    this.bX = {
      min: -Math.PI / 2,
      max: Math.PI / 2,
      value: 0,
      step: 0.01,
      x: 0,
      y: 0,
      label: 'Base X',
      name: 'bX',
    };
    this.bW = {
      min: -600,
      max: 600,
      value: 100,
      step: 0.01,
      x: 0,
      y: 0,
      label: 'Base Width',
      name: 'bW',
    };
    this.bH = {
      min: -600,
      max: 600,
      value: 100,
      step: 0.01,
      x: 0,
      y: 0,
      label: 'Base Height',
      name: 'bH',
    };
    this.stepW = {
      min: -300,
      max: 300,
      value: 100,
      step: 0.01,
      x: 0,
      y: 0,
      label: 'Step W',
      name: 'stepW',
    };
    this.stepH = {
      min: -300,
      max: 300,
      value: 100,
      step: 0.01,
      x: 0,
      y: 0,
      label: 'Step H',
      name: 'stepH',
    };

    this.zIndex = 0;

    this.sliders = [this.bW, this.bH, this.bX];
  }

  drawObject(p) {
    p.push();
    p.translate(this.offsetX, this.offsetY);
    p.rotate(this.bX.value);
    if (this.image) {
      if (!this.bodyImg) {
        // p.loadImage(this.image, (img) => {
        //   this.bodyImg = img;
        // });
        loadAsset(this.image, p, (img) => {
          this.bodyImg = img;
        });
      } else {
        p.imageMode(p.CENTER);
        p.image(this.bodyImg, 0, 0, this.stepW.value, this.stepH.value);
        p.imageMode(p.CORNER);
      }
    }

    p.pop();
  }
}
