import { useState, useEffect } from 'react';
import '../styles/modal.css';

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openModal = (data = null) => {
    setModalData(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalData(null);
  };

  // Add ESC key handler
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isOpen) {
        closeModal();
      }
    };

    // Add event listener when modal is open
    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
    }

    // Cleanup: remove event listener when component unmounts or modal closes
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]); // Only re-run effect when isOpen changes

  const Modal = ({ children }) => {
    if (!isOpen) return null;

    return (
      <div
        className="outer-modal"
        style={{
          minWidth: '350px',
          minHeight: '550px',
          top: '40%',
          maxHeight: '80vh', // Add max height as percentage of viewport height
        }}
      >
        <div className="modal">
          <button 
            className="exit-button" 
            onClick={closeModal}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1001,
              float: 'right'
            }}
          >
            x
          </button>
          <div 
            className="inner-modal"
            style={{
              overflowY: 'auto',
              maxHeight: 'calc(80vh - 60px)',
              paddingRight: '10px'
            }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  };

  return { isOpen, openModal, closeModal, Modal, modalData };
};

export default useModal;
