import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import { GlobalContext } from '../App';
import {
  drawEntitiesWithSound,
  drawEntitiesOnly,
  drawBackgroundImage,
  pauseAllAudio,
  resumeAllAudio,
} from '../utils/p5Instance';
import { saveToTimeline, getCurrentStep } from '../utils/timeline';
import useModal from '../hooks/useModal';
import CharacterModal from '../modals/CharacterModal';
import { drawP5Shape } from '../utils/p5Instance';
import { loadAsset } from '../utils/assetLoader';
import { createCachedTimeline } from '../utils/timeline';

const P5Sketch = () => {
  const {
    backgroundImage,
    characters,
    isPlaying,
    setCurrentTime,
    timeline,
    setTimeline,
    currentTime,
    isRecording,
    objects,
    draggedEntity,
    playedAudioSet,
    dialogs,
    cachedTimeline,
    isCachedTimelineUpdated
  } = useContext(GlobalContext);

  const { isOpen, openModal, closeModal, Modal, modalData } = useModal();

  const playRef = useRef(false);
  const timelineRef = useRef([]);
  const currentTimeRef = useRef(0);
  const isRecordingRef = useRef(false);
  const draggedEntityRef = useRef(null);
  const charactersRef = useRef([]);
  const objectsRef = useRef([]);
  const playedAudioSetRef = useRef(new Set())

  useEffect(() => {
    playRef.current = isPlaying;

    // Pause or resume audio when isPlaying changes
    if (isPlaying) {
      resumeAllAudio();
    } else {
      pauseAllAudio();
    }
  }, [isPlaying]);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  useEffect(() => {
    if (isCachedTimelineUpdated) {
      console.log("set cachedTimeline as timelineRef", timelineRef)
      timelineRef.current = cachedTimeline;
    } else {
      console.log("set timeline as timelineRef", timelineRef)
      //const newTimeline = createCachedTimeline(timeline, dialogs)
      timelineRef.current = timeline;
    }
  }, [timeline, cachedTimeline]);
  

  useEffect(() => {
    isRecordingRef.current = isRecording;
  }, [isRecording]);

  useEffect(() => {
    draggedEntityRef.current = draggedEntity;
  }, [draggedEntity]);

  useEffect(() => {
    charactersRef.current = characters;
  }, [characters]);

  useEffect(() => {
    objectsRef.current = objects;
  }, [objects]);

  useEffect(() => {
    playedAudioSetRef.current = playedAudioSet;
  }, [playedAudioSet]);

  const sketch = useMemo(() => {
    return (p5) => {
      let backgroundImageData = null;
      let offset = { x: 0, y: 0 };
      let mouthImages = [];
      let startTime = 0;
      let selectedEntity = null;
      let defaultEyeLidImage;
      let defaultEyeBallImage;

      p5.preload = () => {
        mouthImages[0] = loadAsset('/mouth/closed.png', p5);
        mouthImages[1] = loadAsset('/mouth/slightly-open.png', p5);
        mouthImages[2] = loadAsset('/mouth/half-open.png', p5);
        mouthImages[3] = loadAsset('/mouth/ds.png', p5);
        mouthImages[4] = loadAsset('/mouth/Ee.png', p5);
        mouthImages[5] = loadAsset('/mouth/open.png', p5);
        mouthImages[6] = loadAsset('/mouth/wide-open.png', p5);
        mouthImages[7] = loadAsset('/mouth/wide-open.png', p5);
        defaultEyeBallImage = loadAsset('/eyes/eye.png', p5);
        defaultEyeLidImage = loadAsset('/eyes/Derek_eyelid.png', p5);
      };

      p5.setup = () => {
        let cnv = p5.createCanvas(2560, 1440);
        cnv.style('width', '2560px');
        cnv.style('height', '1440px');
        p5.frameRate(30);
        startTime = p5.millis();
      };

      // p5.updateWithProps = (props) => {
      //   if (props.backgroundImage) {
      //     backgroundImageData = loadAsset(props.backgroundImage, p5);
      //   } else if (props.backgroundImageUrl) {
      //     backgroundImageData = loadAsset(props.backgroundImageUrl, p5);
      //   }
      // };

      p5.updateWithProps = (props) => {
        if (props.backgroundImage) {
          backgroundImageData = loadAsset(props.backgroundImage, p5);
          console.log("backgroundImageData", backgroundImageData)
        }
      };


      p5.draw = () => {
        const elapsedTime = (p5.millis() - startTime) / 1000;
        p5.background(220);
        drawBackgroundImage(p5, backgroundImageData);
        const currentStep = getCurrentStep(
          timelineRef.current,
          currentTimeRef.current
        );

        if (playRef.current) {
          setCurrentTime((currentTime) => currentTime + p5.deltaTime / 1000);
          drawEntitiesWithSound(
            p5,
            timelineRef.current,
            currentTimeRef.current,
            charactersRef.current,
            objectsRef.current,
            playedAudioSetRef.current,
            dialogs,
          );
        }

        if (p5.keyIsDown(81)) {
          drawEntitiesOnly(
            p5,
            timelineRef.current,
            currentTimeRef.current,
            charactersRef.current,
            objectsRef.current
          ); // for timeline dragging
        }

        // DRAW CHARACTERS
        charactersRef.current.forEach((char) => {
          // Draw the character based on the current step
          if (currentStep) {
            const currentAction = currentStep.actions.find(
              (val) => val.target === char.id
            );

            if (currentAction) {
              // Add all step actions to the currentAction object
              const actionWithStepContext = {
                ...currentAction,
                stepActions: currentStep.actions, // Add all actions from the step
              };
              const eyeLidImage =
                char.eyeLidImageP5 === '' || char.eyeLidImageP5 === undefined
                  ? defaultEyeLidImage
                  : char.eyeLidImageP5;
              const eyeBallImage =
                char.eyeBallImageP5 === ''
                  ? defaultEyeBallImage
                  : char.eyeBallImageP5;
              char.drawCharacter(
                p5,
                mouthImages,
                elapsedTime,
                actionWithStepContext,
                playRef.current,
                currentTimeRef.current,
                eyeBallImage,
                eyeLidImage,
                timeline
              );
            } else {
              return;
            }
          }

          // Handle positions and handles for the character
          const handleSize = 20;
          const bottomLeftX = char.offsetX - char.bW.value / 2;
          const bottomLeftY = char.offsetY + char.bH.value / 2;
          const handleX = char.offsetX + char.bW.value / 2;
          const handleY = char.offsetY + char.bH.value / 2;
          const rotateHandleX = char.offsetX + char.bW.value / 2;
          const rotateHandleY = char.offsetY - char.bH.value / 2;
          const scaleX = char.offsetX - char.bW.value / 2;
          const scaleY = char.offsetY - char.bH.value / 2;

          const isResizeHover =
            p5.mouseX > handleX &&
            p5.mouseX < handleX + handleSize &&
            p5.mouseY > handleY &&
            p5.mouseY < handleY + handleSize;

          const isScaleHover =
            p5.mouseX > scaleX &&
            p5.mouseX < scaleX + handleSize &&
            p5.mouseY > scaleY &&
            p5.mouseY < scaleY + handleSize;

          const isRotateHover =
            p5.mouseX > rotateHandleX - handleSize / 2 &&
            p5.mouseX < rotateHandleX + handleSize / 2 &&
            p5.mouseY > rotateHandleY - handleSize / 2 &&
            p5.mouseY < rotateHandleY + handleSize / 2;

          const isBottomLeftHover =
            p5.mouseX > bottomLeftX &&
            p5.mouseX < bottomLeftX + handleSize &&
            p5.mouseY > bottomLeftY &&
            p5.mouseY < bottomLeftY + handleSize;

          if (!isRecordingRef.current) {
            if (char?.id === selectedEntity?.id) {
              // Global Resize Handle (Bottom Right)
              // p5.fill(isResizeHover ? 'red' : 'white');
              // p5.stroke('black');
              // p5.rect(handleX, handleY, handleSize, handleSize);
              drawP5Shape({
                p5,
                fillColour: isResizeHover ? 'red' : 'white',
                shape: 'rect',
                arg1: handleX,
                arg2: handleY,
                arg3: handleSize,
                arg4: handleSize,
              });
              if (isResizeHover) {
                p5.noStroke();
                p5.fill('black');
                p5.textSize(12);
                p5.textAlign(p5.CENTER, p5.BOTTOM);
                p5.text('Global Resize', handleX + handleSize / 2, handleY - 5);
              }

              // Rotation Handle (Top Right)
              // p5.fill(isRotateHover ? 'blue' : 'white');
              // p5.stroke('black');
              // p5.ellipse(rotateHandleX, rotateHandleY, handleSize, handleSize);
              drawP5Shape({
                p5,
                fillColour: isRotateHover ? 'blue' : 'white',
                shape: 'ellipse',
                arg1: rotateHandleX,
                arg2: rotateHandleY,
                arg3: handleSize,
                arg4: handleSize,
              });
              if (isRotateHover) {
                p5.noStroke();
                p5.fill('black');
                p5.textSize(12);
                p5.textAlign(p5.CENTER, p5.BOTTOM);
                p5.text(
                  'Rotate',
                  rotateHandleX,
                  rotateHandleY - handleSize / 2 - 5
                );
              }

              // Local Resize (Scale) Handle (Top Left)
              // p5.fill(isScaleHover ? 'green' : 'white');
              // p5.stroke('black');
              // p5.rect(scaleX, scaleY, handleSize, handleSize);
              drawP5Shape({
                p5,
                fillColour: isScaleHover ? 'green' : 'white',
                shape: 'rect',
                arg1: scaleX,
                arg2: scaleY,
                arg3: handleSize,
                arg4: handleSize,
              });
              if (isScaleHover) {
                p5.noStroke();
                p5.fill('black');
                p5.textSize(12);
                p5.textAlign(p5.CENTER, p5.BOTTOM);
                p5.text('Local Resize', scaleX + handleSize / 2, scaleY - 5);
              }

              // Bottom Left Handle for modal (Configure)
              // p5.fill(isBottomLeftHover ? 'orange' : 'white');
              // p5.stroke('black');
              // p5.rect(bottomLeftX, bottomLeftY, handleSize, handleSize);
              drawP5Shape({
                p5,
                fillColour: isBottomLeftHover ? 'orange' : 'white',
                shape: 'rect',
                arg1: bottomLeftX,
                arg2: bottomLeftY,
                arg3: handleSize,
                arg4: handleSize,
              });
              if (isBottomLeftHover) {
                p5.noStroke();
                p5.fill('black');
                p5.textSize(12);
                p5.textAlign(p5.CENTER, p5.BOTTOM);
                p5.text(
                  'Configure',
                  bottomLeftX + handleSize / 2,
                  bottomLeftY - 5
                );
              }
            }
          }
          if (!playRef.current && !isRecordingRef.current) {
            // *********************
            // Draw draggable area outline for character
            // This rectangle matches the mousedown detection area:
            p5.push();
            p5.noFill();
            p5.stroke('orange');
            p5.strokeWeight(2);
            p5.rect(char.offsetX - 50, char.offsetY - 50, 100, 100);
            p5.pop();
            // *********************
          }
        });

        // SORT AND DRAW OBJECTS
        objectsRef.current.sort((a, b) => a.zIndex - b.zIndex);

        objectsRef.current.forEach((obj) => {
          obj.drawObject(p5);

          if (!isRecordingRef.current) {
            if (obj?.id === selectedEntity?.id) {
              const handleSize = 20;
              const handleX = obj.offsetX + obj.bW.value / 2;
              const handleY = obj.offsetY + obj.bH.value / 2;
              const rotateHandleX = obj.offsetX + obj.bW.value / 2;
              const rotateHandleY = obj.offsetY - obj.bH.value / 2;

              const isResizeHover =
                p5.mouseX > handleX &&
                p5.mouseX < handleX + handleSize &&
                p5.mouseY > handleY &&
                p5.mouseY < handleY + handleSize;

              const isRotateHover =
                p5.dist(p5.mouseX, p5.mouseY, rotateHandleX, rotateHandleY) <
                handleSize / 2;

              // Draw resize handle
              // p5.fill(isResizeHover ? 'red' : 'white');
              // p5.stroke('black');
              // p5.rect(handleX, handleY, handleSize, handleSize);
              drawP5Shape({
                p5,
                fillColour: isResizeHover ? 'red' : 'white',
                shape: 'rect',
                arg1: handleX,
                arg2: handleY,
                arg3: handleSize,
                arg4: handleSize,
              });

              // Draw rotation handle
              // p5.fill(isRotateHover ? 'blue' : 'white');
              // p5.stroke('black');
              // p5.ellipse(rotateHandleX, rotateHandleY, handleSize, handleSize);
              drawP5Shape({
                p5,
                fillColour: isRotateHover ? 'blue' : 'white',
                shape: 'rect',
                arg1: rotateHandleX,
                arg2: rotateHandleY,
                arg3: handleSize,
                arg4: handleSize,
              });

              // Tooltips for object handles
              if (isResizeHover) {
                p5.noStroke();
                p5.fill('black');
                p5.textSize(12);
                p5.textAlign(p5.CENTER, p5.BOTTOM);
                p5.text('Resize', handleX + handleSize / 2, handleY - 5);
              }

              if (isRotateHover) {
                p5.noStroke();
                p5.fill('black');
                p5.textSize(12);
                p5.textAlign(p5.CENTER, p5.BOTTOM);
                p5.text(
                  'Rotate',
                  rotateHandleX,
                  rotateHandleY - handleSize / 2 - 5
                );
              }
            }
          }

          if (!playRef.current && !isRecordingRef.current) {
            // *********************
            // Draw draggable area outline for object
            p5.push();
            p5.noFill();
            p5.stroke('orange');
            p5.strokeWeight(2);
            p5.rect(obj.offsetX - 50, obj.offsetY - 50, 100, 100);
            p5.pop();
            // *********************
          }
        });
      };

      p5.mousePressed = function () {
        // Check objects first
        for (let i = 0; i < objectsRef.current.length; i++) {
          const obj = objectsRef.current[i];
          const handleSize = 20;

          // Check resize handle
          const handleX = obj.offsetX + obj.bW.value / 2;
          const handleY = obj.offsetY + obj.bH.value / 2;
          const isResizeArea =
            p5.mouseX > handleX &&
            p5.mouseX < handleX + handleSize &&
            p5.mouseY > handleY &&
            p5.mouseY < handleY + handleSize;

          if (isResizeArea) {
            draggedEntityRef.current = {
              id: obj.id,
              type: 'resize',
              isObject: true,
            };
            return;
          }

          // Check rotation handle
          const rotateHandleX = obj.offsetX + obj.bW.value / 2;
          const rotateHandleY = obj.offsetY - obj.bH.value / 2;
          const isRotateArea =
            p5.dist(p5.mouseX, p5.mouseY, rotateHandleX, rotateHandleY) <
            handleSize / 2;

          if (isRotateArea) {
            draggedEntityRef.current = {
              id: obj.id,
              type: 'rotate',
              isObject: true,
            };
            return;
          }

          // Check move area for objects
          if (
            p5.mouseX > obj.offsetX - 50 &&
            p5.mouseX < obj.offsetX + 50 &&
            p5.mouseY > obj.offsetY - 50 &&
            p5.mouseY < obj.offsetY + 50
          ) {
            draggedEntityRef.current = {
              id: obj.id,
              type: 'move',
              isObject: true,
            };
            offset.x = obj.offsetX - p5.mouseX;
            offset.y = obj.offsetY - p5.mouseY;
            selectedEntity = obj;
            return;
          }
        }

        // Check characters
        for (let i = 0; i < charactersRef.current.length; i++) {
          const char = charactersRef.current[i];

          const handleSize = 20;
          const handleX = char.offsetX + char.bW.value / 2;
          const handleY = char.offsetY + char.bH.value / 2;
          const rotateHandleX = char.offsetX + char.bW.value / 2;
          const rotateHandleY = char.offsetY - char.bH.value / 2;
          const scaleX = char.offsetX - char.bW.value / 2;
          const scaleY = char.offsetY - char.bH.value / 2;

          // Detect resize handle click
          const isResizeArea =
            p5.mouseX > handleX &&
            p5.mouseX < handleX + handleSize &&
            p5.mouseY > handleY &&
            p5.mouseY < handleY + handleSize;

          if (isResizeArea) {
            draggedEntityRef.current = { id: char.id, type: 'resize' };
            return;
          }

          // Detect local scale handle click
          const isScaleArea =
            p5.mouseX > scaleX &&
            p5.mouseX < scaleX + handleSize &&
            p5.mouseY > scaleY &&
            p5.mouseY < scaleY + handleSize;

          if (isScaleArea) {
            draggedEntityRef.current = { id: char.id, type: 'scale' };
            return;
          }

          // Detect rotation handle click
          const isRotateArea =
            p5.dist(p5.mouseX, p5.mouseY, rotateHandleX, rotateHandleY) <
            handleSize / 2;
          if (isRotateArea) {
            draggedEntityRef.current = { id: char.id, type: 'rotate' };
            return;
          }

          // Bottom Left Handle Detection – Open Modal Instead
          const bottomLeftX = char.offsetX - char.bW.value / 2;
          const bottomLeftY = char.offsetY + char.bH.value / 2;
          const isBottomLeftArea =
            p5.mouseX > bottomLeftX &&
            p5.mouseX < bottomLeftX + handleSize &&
            p5.mouseY > bottomLeftY &&
            p5.mouseY < bottomLeftY + handleSize;

          if (isBottomLeftArea) {
            openModal(char.id);
          }

          // Check move area for characters
          if (
            p5.mouseX > char.offsetX - 50 &&
            p5.mouseX < char.offsetX + 50 &&
            p5.mouseY > char.offsetY - 50 &&
            p5.mouseY < char.offsetY + 50
          ) {
            draggedEntityRef.current = {
              id: char.id,
              type: 'move',
              isObject: false,
            };
            offset.x = char.offsetX - p5.mouseX;
            offset.y = char.offsetY - p5.mouseY;
            selectedEntity = char; // Assign the clicked character to selectedEntity
            return;
          }
        }
      };

      p5.mouseDragged = function () {
        if (draggedEntityRef.current) {
          if (draggedEntityRef.current.isObject) {
            for (let i = 0; i < objectsRef.current.length; i++) {
              const obj = objectsRef.current[i];
              if (obj.id === draggedEntityRef.current.id) {
                if (draggedEntityRef.current.type === 'move') {
                  obj.offsetX = p5.mouseX + offset.x;
                  obj.offsetY = p5.mouseY + offset.y;
                }
                if (draggedEntityRef.current.type === 'resize') {
                  const newWidth = Math.max(10, p5.mouseX - obj.offsetX) * 2;
                  const newHeight = Math.max(10, p5.mouseY - obj.offsetY) * 2;
                  obj.bW.value = newWidth;
                  obj.bH.value = newHeight;
                  obj.stepW.value = newWidth;
                  obj.stepH.value = newHeight;
                }
                if (draggedEntityRef.current.type === 'rotate') {
                  const dx = p5.mouseX - obj.offsetX;
                  const dy = p5.mouseY - obj.offsetY;
                  obj.bX.value = Math.atan2(dy, dx);
                }
              }
            }
          } else {
            for (let i = 0; i < charactersRef.current.length; i++) {
              const char = charactersRef.current[i];
              if (char.id === draggedEntityRef.current.id) {
                if (draggedEntityRef.current.type === 'move') {
                  char.offsetX = p5.mouseX + offset.x;
                  char.offsetY = p5.mouseY + offset.y;
                }
                if (draggedEntityRef.current.type === 'resize') {
                  char.bW.value = Math.max(10, p5.mouseX - char.offsetX);
                  char.bH.value = Math.max(10, p5.mouseY - char.offsetY);
                }
                if (draggedEntityRef.current.type === 'rotate') {
                  const dx = p5.mouseX - char.offsetX;
                  const dy = p5.mouseY - char.offsetY;
                  char.bX.value = Math.atan2(dy, dx);
                }
                if (draggedEntityRef.current.type === 'scale') {
                  char.stepW.value = Math.max(10, char.offsetX - p5.mouseX);
                  char.stepH.value = Math.max(10, char.offsetY - p5.mouseY);
                }
              }
            }
          }
        }
      };

      p5.mouseReleased = function () {
        if (draggedEntityRef.current) {
          if (draggedEntityRef.current.isObject) {
            for (let i = 0; i < objectsRef.current.length; i++) {
              const obj = objectsRef.current[i];
              if (obj.id === draggedEntityRef.current.id) {
                const newX = obj.offsetX;
                const newY = obj.offsetY;
                const newRotation = p5.degrees(obj.bX.value) || 0;
                const stepW = obj.stepW.value;
                const stepH = obj.stepH.value;
                //console.log(`Object ${draggedEntityRef.current.id} released at X: ${newX}, Y: ${newY} at Rotation ${newRotation}`);
                const updatedTimeline = saveToTimeline(
                  timelineRef.current,
                  currentTimeRef.current,
                  draggedEntityRef.current,
                  newX,
                  newY,
                  newRotation,
                  obj,
                  stepW,
                  stepH
                );
                setTimeline(updatedTimeline);
              }
            }
          } else {
            for (let i = 0; i < charactersRef.current.length; i++) {
              const value = charactersRef.current[i];
              if (value.id === draggedEntityRef.current.id) {
                const newX = value.offsetX;
                const newY = value.offsetY;
                const newRotation = p5.degrees(value.bX.value) || 0;
                const stepW = value.stepW.value;
                const stepH = value.stepH.value;
                console.log(
                  `Entity ${draggedEntityRef.current.id} released at X: ${newX}, Y: ${newY} at Rotation ${newRotation}`
                );
                const updatedTimeline = saveToTimeline(
                  timelineRef.current,
                  currentTimeRef.current,
                  draggedEntityRef.current,
                  newX,
                  newY,
                  newRotation,
                  value,
                  stepW,
                  stepH
                );
                setTimeline(updatedTimeline);
              }
            }
          }
          draggedEntityRef.current = null;
        }
      };
    };
  }, [backgroundImage]);

  return (
    <>
      <ReactP5Wrapper
        sketch={sketch}
        className="canvas-container"
        backgroundImage={backgroundImage}
      />
      <CharacterModal
        isOpen={isOpen}
        closeModal={closeModal}
        modalData={modalData}
      />
    </>
  );
};

export default P5Sketch;
