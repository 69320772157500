// createNewChar.js
import { v4 as uuidv4 } from 'uuid';
import { RegularCharacter } from '../constants/regular';
import { getCurrentStepIndex, normalizeTimelineTimes } from '../utils/timeline';

/**
 * Creates a new character based on an image file and updates the provided state.
 *
 * @param {Object} params - The parameters object.
 * @param {File} params.file - The image file to use for the character.
 * @param {Array} params.characters - The current array of characters.
 * @param {Function} params.setCharacters - Function to update the characters array.
 * @param {Array} params.timeline - The current timeline array.
 * @param {Function} params.setTimeline - Function to update the timeline.
 * @param {number} params.currentTime - The current time in the timeline.
 * @param {Function} [params.alertFn=window.alert] - Optional function for alert messages.
 *
 * @returns {Promise<Object>} Resolves with the newly created character.
 */
export function createNewChar({
  file,
  characters,
  setCharacters,
  timeline,
  setTimeline,
  currentTime,
  alertFn = window.alert,
  option
}) {
  if (!file) {
    alertFn('No image file provided for creating a new character.');
    return Promise.reject(new Error('No file provided'));
  }

  const fileNameWithoutExt = file.name.split('.').slice(0, -1).join('.');
  const existingCharacter = characters.find(
    (char) => char.id === fileNameWithoutExt
  );
  if (existingCharacter) {
    alertFn(`A character with the ID '${fileNameWithoutExt}' already exists.`);
    return Promise.reject(new Error('Character already exists'));
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const imageBase64 = reader.result;
      const randomUuid = uuidv4();
      const name = randomUuid;
      const finalId = fileNameWithoutExt;

      // Loop through the current characters to find the maximum z-index
      const maxZIndex = characters.reduce((max, char) => {
        // If zIndex is undefined, assume 0.
        return (char.zIndex || 0) > max ? char.zIndex : max;
      }, 0);

      // Create a new character instance and assign its zIndex as maxZIndex + 1
      const newChar = new RegularCharacter(
        50,
        50,
        finalId,
        name,
        option === "base64" ? imageBase64 : file.name,
        file.name
      );
      newChar.zIndex = maxZIndex + 1;

      // Add the new character to the existing list
      setCharacters([...characters, newChar]);

      // Update the timeline with an initial "move" action for the new character
      const currentIndex = getCurrentStepIndex(timeline, currentTime);
      let updatedTimeline = [...timeline];
      const currentStep =
        updatedTimeline.length > 0 ? updatedTimeline[currentIndex] : null;

      const newAction = {
        target: finalId,
        type: 'move',
        parameters: {
          position: { x: 50, y: 50 },
          duration: 1,
          easing: 'linear',
        },
      };

      if (currentStep) {
        const existingAction = currentStep.actions.find(
          (action) => action.target === finalId
        );
        if (!existingAction) {
          updatedTimeline[currentIndex] = {
            ...currentStep,
            actions: [...currentStep.actions, newAction],
          };
        }
      } else {
        // No current timeline step exists—create a new one
        const newStep = {
          time: currentTime,
          actions: [newAction],
        };
        updatedTimeline.push(newStep);
        updatedTimeline.sort((a, b) => a.time - b.time); // Keep timeline sorted
        updatedTimeline = normalizeTimelineTimes(updatedTimeline);
      }

      setTimeline(updatedTimeline);
      resolve(newChar);
    };

    reader.onerror = () => {
      reject(new Error('Error reading file'));
    };

    reader.readAsDataURL(file);
  });
}

// utils/characterUtils.js

/**
 * Linearly interpolates between two values.
 */
export function lerpValue(current, target, factor) {
  return current + (target - current) * factor;
}

/**
 * Calculate the mouth dimensions based on the current size percentage.
 */
export function calculateMouthDimensions(bodyWidth, bodyHeight, mouthSize) {
  const mouthW = bodyWidth * (mouthSize / 100);
  const mouthH = bodyHeight * (mouthSize / 100);
  return { mouthW, mouthH };
}

/**
 * Calculate the mouth position offset based on the percentage offsets.
 */
export function calculateMouthPosition(
  bodyWidth,
  bodyHeight,
  offsetXPercent,
  offsetYPercent,
  centerPercent = 50
) {
  const mouthX = ((offsetXPercent - centerPercent) / 100) * bodyWidth;
  const mouthY = ((offsetYPercent - centerPercent) / 100) * bodyHeight;
  return { mouthX, mouthY };
}

/**
 * Given an eye socket (array of points in percentages) and the body dimensions,
 * compute its bounding box in pixel coordinates.
 */
export function getEyeSocketBoundingBox(eyeSocket, bodyWidth, bodyHeight) {
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;
  eyeSocket.forEach((point) => {
    minX = Math.min(minX, point.x);
    minY = Math.min(minY, point.y);
    maxX = Math.max(maxX, point.x);
    maxY = Math.max(maxY, point.y);
  });
  return {
    minXPixel: (minX / 100 - 0.5) * bodyWidth,
    maxXPixel: (maxX / 100 - 0.5) * bodyWidth,
    minYPixel: (minY / 100 - 0.5) * bodyHeight,
    maxYPixel: (maxY / 100 - 0.5) * bodyHeight,
  };
}

/**
 * Calculate the final eye position in pixel coordinates.
 */
export function calculateEyePosition(boundingBox, eyePos) {
  const centerX = (boundingBox.minXPixel + boundingBox.maxXPixel) / 2;
  const centerY = (boundingBox.minYPixel + boundingBox.maxYPixel) / 2;
  // The offset is scaled relative to the bounding box dimensions.
  const offsetX =
    (eyePos.x - 0.5) * (boundingBox.maxXPixel - boundingBox.minXPixel);
  const offsetY =
    (eyePos.y - 0.5) * (boundingBox.maxYPixel - boundingBox.minYPixel);
  return { eyeX: centerX + offsetX, eyeY: centerY + offsetY };
}

/**
 * Compute the drawing dimensions for the eye image while preserving its aspect ratio.
 */
export function calculateEyeDimensions(boundingBox, eyeImage) {
  const calculatedEyeWidth =
    (boundingBox.maxXPixel - boundingBox.minXPixel) * 0.7;
  const eyeAspectRatio = eyeImage.width / eyeImage.height;
  const calculatedEyeHeight = calculatedEyeWidth / eyeAspectRatio;
  return { calculatedEyeWidth, calculatedEyeHeight };
}

/**
 * Randomly choose an eye position based on predefined directions.
 */
export function getRandomEyePosition() {
  const directions = [
    { x: 0.5, y: 0 }, // top
    { x: 0.5, y: 1 }, // bottom
    { x: 0, y: 0.5 }, // left
    { x: 1, y: 0.5 }, // right
    { x: 0, y: 0 }, // left-top
    { x: 0, y: 1 }, // left-bottom
    { x: 1, y: 0 }, // right-top
    { x: 1, y: 1 }, // right-bottom
  ];
  return directions[Math.floor(Math.random() * directions.length)];
}

export const updateCharacterState = (characters, id, property, value) => {
  return characters.map((char) => {
    if (char.id === id) {
      const updatedChar = { ...char, [property]: value };
      Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
      return updatedChar;
    }
    return char;
  });
};

// utils/timelineUtils.js
export const updateTimelineAction = (
  timeline,
  currentTime,
  id,
  actionUpdates
) => {
  const currentStepIndex = getCurrentStepIndex(timeline, currentTime);
  if (currentStepIndex < 0) {
    alert('No current step found.');
    return timeline;
  }

  const currentStep = timeline[currentStepIndex];
  let actionFound = false;

  const updatedActions =
    currentStep.actions?.map((action) => {
      if (action.target === id) {
        actionFound = true;
        return { ...action, ...actionUpdates };
      }
      return action;
    }) || [];

  if (!actionFound) {
    updatedActions.push({ target: id, ...actionUpdates });
  }

  const updatedStep = { ...currentStep, actions: updatedActions };
  const updatedTimeline = [...timeline];
  updatedTimeline[currentStepIndex] = updatedStep;

  return updatedTimeline;
};

// utils/fileUtils.js
export const uploadImage = (
  fileInputRef,
  id,
  property,
  setCharacters,
  notify
) => {
  if (!fileInputRef.current?.files?.length) {
    notify('Please select an image file first');
    return;
  }

  const file = fileInputRef.current.files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const base64Image = e.target.result;
    setCharacters((prevCharacters) =>
      prevCharacters.map((char) => {
        if (char.id === id) {
          const updatedChar = { ...char, [property]: base64Image };
          Object.setPrototypeOf(updatedChar, RegularCharacter.prototype);
          return updatedChar;
        }
        return char;
      })
    );
    notify(`${property} image uploaded successfully`);
    fileInputRef.current.value = ''; // Reset the file input
  };

  reader.readAsDataURL(file);
};

// utils/viewUtils.js
export const setCharacterView = (
  timeline,
  currentTime,
  id,
  view,
  setTimeline,
  notify
) => {
  const updatedTimeline = updateTimelineAction(timeline, currentTime, id, {
    view,
  });
  setTimeline(updatedTimeline);
  notify(`Set view to ${view}`);
};

// utils/exportUtils.js
export const exportCharacterUtils = (characters, id, notify) => {
  const char = characters.find((char) => char.id === id);
  if (!char) {
    notify('Character not found');
    return;
  }

  const charForExport = {
    id: char.id,
    name: char.name,
    image: char.image,
    leftImage: char.leftImage,
    rightImage: char.rightImage,
    backImage: char.backImage,
    offsetX: char.offsetX,
    offsetY: char.offsetY,
    zIndex: char.zIndex,
    eyeSockets: char.eyeSockets,
    eyeLidImage: char.eyeLidImage,
    eyeBallImage: char.eyeBallImage,
    eyeUpdateInterval: char.eyeUpdateInterval,
    eyeBlinkInterval: char.eyeBlinkInterval,
    eyeLastUpdatedTime: char.eyeLastUpdatedTime,
    eyeLidLastUpdatedTime: char.eyeLidLastUpdatedTime,
    showEyeLidLimit: char.showEyeLidLimit,
    currentEyeLidCount: char.currentEyeLidCount,
    mOffsetY: char.mOffsetY,
    mOffsetX: char.mOffsetX,
    mSize: char.mSize,
    sliders: char.sliders.map((slider) => ({
      name: slider.name,
      value: slider.value,
      min: slider.min,
      max: slider.max,
      step: slider.step,
      label: slider.label,
    })),
  };

  const jsonString = JSON.stringify(charForExport, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = `char_${char.name || 'character'}_${char.id}.json`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);

  notify(`Character ${char.name || char.id} exported successfully`);
};
